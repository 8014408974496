import React, { useState, useEffect } from 'react';
import AuthUser from '../../../AuthUser';
import $ from 'jquery';
import 'datatables.net';

function Attendance() {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Get current month
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { http, getUserId } = AuthUser();

    useEffect(() => {
        fetchData();
    }, [selectedMonth]);

    const fetchData = async () => {
        try {
            const response = await http.get(`/admin/attendance?month=${selectedMonth}`);
            console.log(response.data)
            setData(response.data);
            initializeDataTable();
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
   
    const initializeDataTable = () => {
        $(document).ready(function() {
            $('#example').DataTable();
        });
     };
    const daysInMonth = data.day1;
    const userPromise = getUserId(1);
   userPromise.then(userData => {
    console.log(userData.data); // Handle the user data here
}).catch(error => {
    console.error('Error fetching user data:', error);
    // Handle the error
});
    
    const renderTableHeaders = () => {
        const headers = [];

        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = new Date(new Date().getFullYear(), selectedMonth - 1, day);
            const dayAbbreviation = currentDate.toLocaleDateString('en-US', { weekday: 'short' });
            headers.push(
                <th key={day}>
                    {dayAbbreviation}<br />
                    {String(day).padStart(2, '0')}
                </th>
            );
        }

        return headers;
    };

    const renderTableRows = () => {
        return data.data.map((row, index) => (
            <tr key={index}>
                <td>{row.userName}</td>
                {renderAttendanceCells(row)}
                <td>{row.totalPresent}</td>
                <td>{row.totalAbsent}</td>
                <td>{row.performance}</td>
                <td>{row.workPerformance}</td>
            </tr>
        ));
    };

    const renderAttendanceCells = (row) => {
        const cells = [];

        for (let day = 1; day <= daysInMonth; day++) {
            const dayData = row[`Day ${day}`];
            let cellContent;

            switch (dayData) {
                case 'P':
                    cellContent = <span style={{ color: 'green', fontSize: '20px' }}><strong>P</strong></span>;
                    break;
                case 'H':
                    cellContent = <span  style={{ color: 'green', fontSize: '20px' }}><strong>P/2<br />(Half)</strong></span>;
                    break;
                case 'A':
                    cellContent = <span  style={{ color: 'red', fontSize: '20px' }}><strong>A</strong></span>;
                    break;
                case 'Sunday':
                    cellContent = <span style={{ color: 'orange', fontSize: '20px' }}><strong>SUN</strong></span>;
                    break;
                default:
                    cellContent = <span style={{ color: 'orange', fontSize: '20px' }}><strong>Holiday</strong></span>;
            }

            cells.push(<td key={day}>{cellContent}</td>);
        }

        return cells;
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div>
                        <button type="button" className="btn btn-success" data-toggle="modal" data-target="#projectTaskModal">
                            Attendance 
                        </button>
                    </div>
                    <div>
                        <select
                            name="selected_month"
                            id="month"
                            className="btn btn-success"
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                        >
                            {Array.from({ length: 12 }, (_, i) => {
                                const monthNumber = i + 1;
                                const monthName = new Date(new Date().getFullYear(), monthNumber - 1, 1)
                                    .toLocaleDateString('en-US', { month: 'long' });
                                return (
                                    <option key={monthNumber} value={monthNumber}>
                                        {monthName}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="card-body card-block">
                    <div className="col-md-12">
                        <div className="table-responsive m-b-40 table-container">
                        {!loading && data.data.length ? ( // If not loading and data is available, render table
                        <table className="table table-data3 table-striped table-bordered" id="example" width="100%">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {renderTableHeaders()}
                                    <th>Total<br /><strong>P</strong></th>
                                    <th>Total<br /><strong>A</strong></th>
                                    <th>Performance</th>
                                    <th>Work<br />Performance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableRows()}
                            </tbody>
                        </table>
                    ) : !loading && !data.data.length ? ( // If not loading and data is not available, show no data message
                        <div className="alert alert-warning" role="alert">
                            No data available.
                        </div>
                    ) : null} // For other cases, render nothing
                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Attendance;
