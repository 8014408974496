// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MasterLayout from './layouts/admin/MasterLayout';
import Login from './Login';

function App() {
  
  return (
    <Router>
      <Routes>
         <Route path="/admin/*" element={<MasterLayout />} />
         <Route path="/adminLogin" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
