import React, { useState, useEffect } from 'react';
import AuthUser from '../../../AuthUser';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import 'datatables.net';

const Technology = () => {
    const [technologies, setTechnologies] = useState([]);
    const [editTechnology, setEditTechnology] = useState({ id: '', technology: '' });
    const { http } = AuthUser();
    const [showMessage, setShowMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMsg] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/technology');
            console.log(response.data.tech)
            setTechnologies(response.data.tech);
            initializeDataTable();
        } catch (error) {
            console.error('Error fetching technologies:', error);
        }
    };

    const handleEdit = (id) => {
        const technologyToEdit = technologies.find(tech => tech.id === id);
        setEditTechnology({ id: technologyToEdit.id, technology: technologyToEdit.technology });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const showConfirmationDialog = (id) => {
        if (window.confirm("Are you sure you want to delete this technology?")) {
            handleDelete(id);
        }
    };
    const handleDelete = async (id) => {
        try {
            const res=await http.delete(`/admin/deleteTechnology/${id}`);
            setMsg(res.data.msg);
            setShowMessage(true);
               setTimeout(() => {
                   setShowMessage(false);
               }, 5000);
            fetchData();
        } catch (error) {
            console.error('Error deleting technology:', error);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editTechnology.id) {
                const res=await http.put(`/admin/updateTechnology/${editTechnology.id}`, editTechnology);
                setMsg(res.data.msg);
                setShowMessage(true);
                setTimeout(() => {
                   setShowMessage(false);
               }, 5000);
            } else {
               let res=await http.post('/admin/add_technology',editTechnology);
               setMsg(res.data.msg);
               setShowMessage(true);
               setTimeout(() => {
                   setShowMessage(false);
               }, 5000);
            }
            setShowModal(false);
            fetchData();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const initializeDataTable = () => {
        $(document).ready(function() {
            $('#example').DataTable();
        });
    };

    return (
        <div>
            <div className="container">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <div>
                            <button type="button" className="btn btn-success" onClick={() => setShowModal(true)}>
                            Add Technology
                            </button>
                        </div>
                        {showMessage && (
                            <div className="alert alert-success text-center" style={{width: 'fit-content',margin: '0 auto'}}>
                                  {msg}
                            </div> 
                        )}
                    </div>

                    <div className="card-body card-block">
                        <div className="col-md-12">
                            <div className="table-responsive m-b-40">
                                <table className="table table-data3 table-striped nowrap" id="example" width="100%">
                                    <thead>
                                        <tr>
                                            <th>S.no.</th>
                                            <th>Technology Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {technologies.map((tech, index) => (
                                            <tr key={tech.id}>
                                                <td>{index + 1}</td>
                                                <td>{tech.technology}</td>
                                                <td className="action-cell">
                                                    <div className="edit-delete-icons">
                                                        <button className="editlink btn btn-success" onClick={() => handleEdit(tech.id)}>
                                                            <i className="fa fa-edit edit-icon" aria-hidden="true"></i>
                                                        </button>
                                                        <button onClick={() => showConfirmationDialog(tech.id)} className="btn btn-danger">
                                                            <i className="fa fa-trash delete-icon" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editTechnology.id ? 'Edit Technology' : 'Add Technology'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="technologyForm" onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="technology">Technology Name</label>
                            <input type="text" className="form-control" id="technology" name="technology" value={editTechnology.technology} onChange={(e) => setEditTechnology({ ...editTechnology, technology: e.target.value })} />
                        </div>
                        <input type="hidden" id="technologyId" name="id" value={editTechnology.id} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFormSubmit}>
                        {editTechnology.id ? 'Update Technology' : 'Add Technology'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Technology;
