import React, { useState, useEffect, useRef } from 'react';
import AuthUser from '../../../AuthUser';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import $ from 'jquery';
import Select from 'react-select';
import 'select2/dist/css/select2.min.css'; 

const Portfolio = () => {
    const [data, setData] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [technology, setTechnology] = useState([]);
    const [plugin, setPlugin] = useState([]);
    const [hosting, setHosting] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedTechnology, setSelectedTechnology] = useState('');
    const [selectedPlugin, setSelectedPlugin] = useState('');
    const [selectedHosting, setSelectedHosting] = useState('');
    const tableRef = useRef(null);
    const { http } = AuthUser();

    const [industryPlaceholderText, setIndustryPlaceholderText] = useState('Select Industry');
    const [technologyPlaceholderText, setTechnologyPlaceholderText] = useState('Select Technology');
    const [pluginPlaceholderText, setPluginPlaceholderText] = useState('Select Plugin');
    const [hostingPlaceholderText, setHostingPlaceholderText] = useState('Select Hosting');


    const industryOptions = industry.map((list) => ({ value: list.id, label: list.industry, type: 'industry' }));
    const technologyOptions = technology.map((list) => ({ value: list.technology, label: list.technology, type: 'technology' }));
    const pluginOptions = plugin.map((list) => ({ value: list.id, label: list.plugin, type: 'plugin' }));
    const hostingOptions = hosting.map((list) => ({ value: list.hosting, label: list.hosting, type: 'hosting' }));


    const [showModal, setShowModal] = useState(false);
    const [selectedModalIndustry, setSelectedModalIndustry] = useState('');
    const [selectedIndustryItems, setSelectedIndustryItems] = useState([]);
    const [modalSearchText, setModalSearchText] = useState('');
    const [selectedModalTechnology, setSelectedModalTechnology] = useState('');
    const [selectedModalPlugin, setSelectedModalPlugin] = useState('');
    const [selectedPluginItems, setSelectedPluginItems] = useState([]);
    const [selectedModalHosting, setSelectedModalHosting] = useState('');
    const [editId, setEditId] = useState(null);
    const [websiteLink, setWebsiteLink] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        const selectedIndustryIds = selectedIndustryItems;
        setSelectedModalIndustry(selectedIndustryIds);
        
    }, [selectedIndustryItems]);

    useEffect(() => {
    const selectedPluginIds = selectedPluginItems;
    setSelectedModalPlugin(selectedPluginIds);
}, [selectedPluginItems]);

     const fetchData = async (retryCount = 0) => {
        try {
            const response = await http.get('/admin/portfolio');
            console.log(response.data)
            setData(response.data.portfolio);
            setIndustry(response.data.industry);
            setTechnology(response.data.technology);
            setPlugin(response.data.plugin);
            setHosting(response.data.hosting);
            initializeDataTable();
            setLoading(false);
        }  catch (error) {
            console.error('Error fetching countries:', error);
        }
    };
    const initializeDataTable = () => {
        $(document).ready(function () {
            // Check if DataTable is already initialized on the table element
            if (!$.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable({
                    ordering: false, // Disable sorting
                    
                });
            }
        });
    };
    
    const handleCheckboxChange = (e) => {
        const id = e.target.value;
        const isChecked = e.target.checked;
        if (isChecked) {
            // Add id to selectedIds
        } else {
            // Remove id from selectedIds
        }
    };
    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption.value);
        setIndustryPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Industry');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
        }
        initializeDataTable();
    };
    const handleTechnologyChange = (selectedOption) => {
        setSelectedTechnology(selectedOption.value);
        setTechnologyPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Technology');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
        }
        initializeDataTable();
    };
    
    const handlePluginChange = (selectedOption) => {
        setSelectedPlugin(selectedOption.value);
        setPluginPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Plugin');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
        }
        initializeDataTable();
    };
    const pluginNamesMap = {};
    plugin.forEach(item => {
        pluginNamesMap[item.id] = item.plugin;
    });
    const handleHostingChange = (selectedOption) => {
        setSelectedHosting(selectedOption.value);
        setHostingPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Hosting');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
        }
        initializeDataTable();
    };
    
    const handleCloseModal = () => {
        setShowModal(false); 
        
    };
    const handleModalSearchChange = (event) => {
        setModalSearchText(event.target.value.toLowerCase());
    };
    const filteredIndustry = industry.filter((item) =>
        item.industry.toLowerCase().includes(modalSearchText)
    );
    const handleModalCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedIndustryItems(prevSelectedItems => {
            if (checked) {
                return [...prevSelectedItems, value];
            } else {
                return prevSelectedItems.filter(item => item !== value);
            }
        });
    };
    const handleAllCheckboxIndustryChange = (event) => {
        const isChecked = event.target.checked;
        const allIndustryIds = filteredIndustry.map((item) => String(item.id));
        setSelectedIndustryItems(isChecked ? allIndustryIds : []);
    };

    const handleModalTechnologyChange = (selectedOption) => {
        setSelectedModalTechnology(selectedOption);
    };
    const handlePluginCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedPluginItems(prevSelectedPlugins => {
            if (checked) {
                return [...prevSelectedPlugins, value];
            } else {
                return prevSelectedPlugins.filter(pluginId => pluginId !== value);
            }
        });
    };
    const filteredPlugin = plugin.filter((item) =>
        item.plugin.toLowerCase().includes(modalSearchText)
    );

    const handlePluginAllCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        const allPluginIds = filteredPlugin.map((item) => String(item.id));
        setSelectedPluginItems(isChecked ? allPluginIds : []);
    };
    
    const handleModalHostingChange = (selectedOption) => {
        setSelectedModalHosting(selectedOption);
    };

    const handleEdit = (portfolioId) => {
        const selectedPortfolioItem = data.find(item => item.id === portfolioId);
        console.log(selectedPortfolioItem)
        const selectedTech = technology.find(tech => tech.technology === selectedPortfolioItem.technology);
        setSelectedModalIndustry(selectedPortfolioItem.industry);
        const selectedHost = hosting.find(host => host.hosting === selectedPortfolioItem.hosting);
        setSelectedModalTechnology({ value: selectedTech.id, label: selectedTech.technology });
        setSelectedModalPlugin(selectedPortfolioItem.plugin);
        setSelectedModalHosting({ value: selectedHost.id, label: selectedHost.hosting });
        setWebsiteLink(selectedPortfolioItem.website_link);
        setEditId(portfolioId);
        setShowModal(true);
       
    };

   

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!websiteLink) {
                Swal.fire('Error', 'The website link field is required.', 'error');
                return;
            }
    
            const formData = {
                industry: selectedModalIndustry,
                technology: selectedModalTechnology,
                plugin: selectedModalPlugin,
                hosting: selectedModalHosting,
                website_link: websiteLink,
                id: editId,
            };
            
            if (editId) {
                // If in edit mode, send a PUT request to update the portfolio item
                const response = await http.put(`/admin/edit_portfolio/${editId}`, formData);
                console.log(response)
                const responseData = response.data;
                if (response.status === 200) {
                    Swal.fire('Success', responseData.msg, 'success');
                    setMsg(responseData.msg);
                    setShowMessage(true);
                    setTimeout(() => {
                        setShowMessage(false);
                    }, 5000);
                    setShowModal(false);
                    resetForm();
                    fetchData();
                } else {
                    // Handle validation errors
                    if (responseData.errors) {
                        const errorMessage = responseData.errors.join('\n');
                        Swal.fire('Error', errorMessage, 'error');
                    } else {
                        Swal.fire('Error', 'An error occurred while updating the portfolio.', 'error');
                    }
                }
            }else {
                const response = await http.post('/admin/add_portfolio', formData);
                console.log(response)
                const responseData = response.data;
            
                
                if (response.status === 200) {
                    Swal.fire('Success', responseData.msg, 'success');
                    setMsg(responseData.msg);
                    setShowMessage(true);
                    setSelectedIndustry('');
                    setSelectedModalTechnology('');
                    setSelectedPlugin('');
                    setSelectedModalHosting('');
                    setWebsiteLink('');
                    setTimeout(() => {
                        setShowMessage(false);
                    }, 5000);
                    setShowModal(false);
                    resetForm();
                    fetchData();
                } else {
                    // Handle validation errors
                    if (responseData.errors) {
                        const errorMessage = responseData.errors.join('\n');
                        Swal.fire('Error', errorMessage, 'error');
                    } else {
                        Swal.fire('Error', 'An error occurred while submitting the form.', 'error');
                    }
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire('Error', 'An error occurred while submitting the form.', 'error');
        }
    };
    
    const resetForm = () => {
        setSelectedIndustryItems([]);
        setSelectedModalTechnology(null);
        setSelectedPluginItems([]);
        setSelectedModalHosting(null);
        setWebsiteLink('');
    };
    
    const showConfirmationDialog = (id) => {
        if (window.confirm("Are you sure you want to delete this Portfolio?")) {
            handleDelete(id);
        }
    };
    const handleDelete = async (id) => {
        try {
            const res = await http.delete(`/admin/deletePortfolio/${id}`);
            setMsg(res.data.msg);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            Swal.fire('Success', res.data.msg, 'success');
            fetchData();
        } catch (error) {
            console.error('Error deleting country:', error);
        }
    };

    
    return (
        <>
        {loading ? ( // Show loading message when data is being fetched
                <div>Loading...</div>
            ) : (
            <div className="container">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <div>
                        <button type="button" className="btn btn-success" onClick={() => setShowModal(true)}>
                                Add Portfolio
                            </button>
                        </div>
                        {showMessage && (
                            <div className="alert alert-success text-center" style={{ width: 'fit-content', margin: '0 auto' }}>
                                {msg}
                            </div>
                        )}
                    </div>
                    <div className="card-body card-block">
                        <div className="col-md-12">
                            <div className="table-responsive m-b-40">
                                <table className="table table-data3 table-striped nowrap" width="100%" id="example" ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox" id="allSelect" />
                                                All
                                            </th>
                                            <th>S.no.</th>
                                            <th>
                                                <Select 
                                                    className="filter" 
                                                    options={[
                                                        { value: '', label: 'Select Industry', type: 'placeholder' },
                                                        ...industryOptions
                                                    ]}
                                                    placeholder={industryPlaceholderText} // Placeholder text for the search input
                                                    value={selectedIndustry} 
                                                    onChange={handleIndustryChange} 
                                                />
                                            </th>
                                            <th>Website Link</th>
                                            <th>
                                                <Select className="filter"
                                                    options={[
                                                        { value: '', label: 'Select Technology', type: 'placeholder' },
                                                        ...technologyOptions
                                                    ]}
                                                    placeholder={technologyPlaceholderText}
                                                    value={selectedTechnology} 
                                                    onChange={handleTechnologyChange} />
                                            </th>
                                            <th>
                                                <Select className="filter"
                                                    options={[
                                                    { value: '', label: 'Select Plugin', type: 'placeholder' },
                                                    ...pluginOptions
                                                ]}
                                                placeholder={pluginPlaceholderText}
                                                value={selectedPlugin} 
                                                onChange={handlePluginChange} />
                                          </th>
                                          <th>
                                            <Select className="filter"
                                                options={[
                                                { value: '', label: 'Select Hosting', type: 'placeholder' },
                                                ...hostingOptions
                                            ]}
                                            placeholder={hostingPlaceholderText}
                                            value={selectedHosting} 
                                            onChange={handleHostingChange} />
                                        </th>
                                        <th>Created By</th>
                                        <th>Updated By</th>
                                        <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data
                                            .filter((item) => {
                                                  const industryMatch = selectedIndustry === '' || (item.industry && item.industry.split(',').some(industryId => industryId.trim() === selectedIndustry.toString()));
                                                  const technologyMatch = selectedTechnology === '' || item.technology === selectedTechnology;
                                                  const hostingMatch = selectedHosting === '' || item.hosting === selectedHosting;
                                                  const pluginMatch = selectedPlugin === '' || (item.plugin && item.plugin.split(',').some(pluginId => pluginId.trim() === selectedPlugin.toString()));
                                                  
                                            return industryMatch && technologyMatch && pluginMatch && hostingMatch;
                                            })
                                            .map((list, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            id={`selected-${list.id}`}
                                                            value={list.id}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </td>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                            {list.industry.split(',').map((industryId, i) => {
                                                                const industryName = industry.find(item => item.id === parseInt(industryId))?.industry;
                                                                return industryName;
                                                            }).filter(industry => industry).join(', ')}
                                                    </td>
                                                    <td>
                                                        <a href={list.website_link} target="_blank">
                                                            {list.website_link}
                                                        </a>
                                                    </td>
                                                    <td>{list.technology}</td>
                                                    <td>
                                                        {list.plugin.split(',').map(pluginId => pluginNamesMap[parseInt(pluginId)]).filter(Boolean).join(', ')}
                                                    </td>
                                                    <td>{list.hosting}</td>
                                                    <td>{list.created_by}</td>
                                                    <td>{list.updated_by}</td>
                                                    <td className="action-cell">
                                                    <div className="edit-delete-icons">
                                                        <button className="editlink btn btn-success" onClick={() => handleEdit(list.id)}>
                                                            <i className="fa fa-edit edit-icon" aria-hidden="true"></i>
                                                        </button>
                                                        <button onClick={() => showConfirmationDialog(list.id)} className="btn btn-danger">
                                                            <i className="fa fa-trash delete-icon" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

            <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{editId ? 'Edit Portfolio' : 'Add Portfolio'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="portfolioForm" onSubmit={handleSubmit}>
                <input type="text" id="portfolioId" name="id" value={editId} />
                <div className="form-group">
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="industryDropdown" style={{maxWidth: '300px'}}>
                        Select Industry
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <div className="input-group mb-2">
                            <input
                                type="text"
                                className="form-control"
                                id="industrySearch"
                                placeholder="Search Industry"
                                onChange={handleModalSearchChange}
                            />
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                            <input
                                type="checkbox"
                                id="industry-all"
                                checked={selectedIndustryItems.length === filteredIndustry.length}
                                onChange={handleAllCheckboxIndustryChange}
                            />
                            <label htmlFor="industry-all">All</label>
                        </div>
                        {filteredIndustry.map((item) => {
                            const industryIds = Array.isArray(selectedModalIndustry) ? selectedModalIndustry : String(selectedModalIndustry).split(','); 
                            return (
                                <div className="dropdown-item" key={item.id}>
                                    <input
                                        type="checkbox"
                                        className="industry-checkbox"
                                        id={`industry-${item.id}`}
                                        value={item.id}
                                        checked={industryIds.includes(String(item.id))} // Check if the industry ID is included
                                        onChange={handleModalCheckboxChange}
                                    />
                                    <label htmlFor={`industry-${item.id}`}>&nbsp;{item.industry}</label>
                                </div>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="form-group">
                <label htmlFor="websiteLink">Website Link</label>
                <input type="text" className="form-control" id="websiteLink" value={websiteLink} onChange={(e) => setWebsiteLink(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="technology">Technology</label>
                <Select
                    
                    options={technology.map(tech => ({ value: tech.id, label: tech.technology }))}
                    value={selectedModalTechnology}
                    onChange={handleModalTechnologyChange}
                    isClearable={true}
                    placeholder="Select Technology"
                />
            </div>
            <div className="form-group">
                <label>Plugin</label>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="pluginDropdown" style={{ maxWidth: '300px' }}>
                        Select Plugin
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <div className="input-group mb-2">
                            <input
                                type="text"
                                className="form-control"
                                id="pluginSearch"
                                placeholder="Search Plugin"
                                onChange={handleModalSearchChange}
                            />
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                            <input
                                type="checkbox"
                                id="plugin-all"
                                checked={selectedPluginItems.length === filteredPlugin.length}
                                onChange={handlePluginAllCheckboxChange}
                            />
                            <label htmlFor="plugin-all">All</label>
                        </div>
                        {filteredPlugin.map((item) => {
                            const pluginIds = Array.isArray(selectedModalPlugin) ? selectedModalPlugin : String(selectedModalPlugin).split(','); // Check if selectedPlugin is an array or convert to string and split
                            return (
                                <div className="dropdown-item" key={item.id}>
                                    <input
                                        type="checkbox"
                                        className="plugin-checkbox"
                                        id={`plugin-${item.id}`}
                                        value={item.id}
                                        checked={pluginIds.includes(String(item.id))} // Check if the plugin ID is included
                                        onChange={handlePluginCheckboxChange}
                                    />
                                    <label htmlFor={`plugin-${item.id}`}>&nbsp;{item.plugin}</label>
                                </div>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="form-group">
                <label htmlFor="hosting">Hosting</label>
                <Select
                    options={hosting.map(h => ({ value: h.id, label: h.hosting }))}
                    value={selectedModalHosting}
                    onChange={handleModalHostingChange}
                    isClearable={true}
                    placeholder="Select Hosting"
                />
            </div>
                    
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                            {editId ? 'Update Portfolio' : 'Add Portfolio'}
                        </button>
                    </div>
                </form>
            </Modal.Body>
                    </Modal>
        </>
    );
};

export default Portfolio;
