import React, { useState, useEffect } from 'react';
import AuthUser from '../../../AuthUser';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import 'datatables.net';

const Hosting = () => {
    const [hostings, setHostings] = useState([]);
    const [editHosting, setEditHosting] = useState({ id: '', hosting: '' });
    const { http } = AuthUser();
    const [showMessage, setShowMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMsg] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/hosting');
            console.log(response.data.hosting);
            setHostings(response.data.hosting);
            initializeDataTable();
        } catch (error) {
            console.error('Error fetching hostings:', error);
        }
    };

    const handleEdit = (id) => {
        const hostingToEdit = hostings.find(hst => hst.id === id);
        setEditHosting({ id: hostingToEdit.id, hosting: hostingToEdit.hosting });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const showConfirmationDialog = (id) => {
        if (window.confirm("Are you sure you want to delete this hosting?")) {
            handleDelete(id);
        }
    };

    const handleDelete = async (id) => {
        try {
            const res = await http.delete(`/admin/deleteHosting/${id}`);
            setMsg(res.data.msg);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            fetchData();
        } catch (error) {
            console.error('Error deleting hosting:', error);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editHosting.id) {
                const res = await http.put(`/admin/updateHosting/${editHosting.id}`, editHosting);
                setMsg(res.data.msg);
                setEditHosting('');
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
            } else {
                let res = await http.post('/admin/add_hosting', editHosting);
                setMsg(res.data.msg);
                setEditHosting('');
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
            }
            setShowModal(false);
            fetchData();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const initializeDataTable = () => {
        $(document).ready(function () {
            $('#example').DataTable();
        });
    };

    return (
        <>
            <div className="container">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <div>
                            <button type="button" className="btn btn-success" onClick={() => setShowModal(true)}>
                                Add Hosting
                            </button>
                        </div>
                        {showMessage && (
                            <div className="alert alert-success text-center" style={{ width: 'fit-content', margin: '0 auto' }}>
                                {msg}
                            </div>
                        )}
                    </div>

                    <div className="card-body card-block">
                        <div className="col-md-12">
                            <div className="table-responsive m-b-40">
                                <table className="table table-data3 table-striped nowrap" id="example" width="100%">
                                    <thead>
                                        <tr>
                                            <th>S.no.</th>
                                            <th>Hosting Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {hostings.map((hst, index) => (
                                            <tr key={hst.id}>
                                                <td>{index + 1}</td>
                                                <td>{hst.hosting}</td>
                                                <td className="action-cell">
                                                    <div className="edit-delete-icons">
                                                        <button className="editlink btn btn-success" onClick={() => handleEdit(hst.id)}>
                                                            <i className="fa fa-edit edit-icon" aria-hidden="true"></i>
                                                        </button>
                                                        <button onClick={() => showConfirmationDialog(hst.id)} className="btn btn-danger">
                                                            <i className="fa fa-trash delete-icon" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editHosting.id ? 'Edit Hosting' : 'Add Hosting'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="hostingForm" onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="hosting">Hosting Name</label>
                            <input type="text" className="form-control" id="hosting" name="hosting" value={editHosting.hosting} onChange={(e) => setEditHosting({ ...editHosting, hosting: e.target.value })} required />
                        </div>
                        <input type="hidden" id="hostingId" name="id" value={editHosting.id} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFormSubmit}>
                        {editHosting.id ? 'Update Hosting' : 'Add Hosting'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Hosting;
