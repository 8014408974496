import React, { useState, useEffect } from 'react';
import AuthUser from '../../../AuthUser';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import 'datatables.net';

const Plugin = () => {
    const [plugins, setPlugins] = useState([]);
    const [editPlugin, setEditPlugin] = useState({ id: '', plugin: '' });
    const { http } = AuthUser();
    const [showMessage, setShowMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMsg] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/plugin');
            console.log(response.data.plugin);
            setPlugins(response.data.plugin);
            initializeDataTable();
        } catch (error) {
            console.error('Error fetching plugins:', error);
        }
    };

    const handleEdit = (id) => {
        const pluginToEdit = plugins.find(plg => plg.id === id);
        setEditPlugin({ id: pluginToEdit.id, plugin: pluginToEdit.plugin });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const showConfirmationDialog = (id) => {
        if (window.confirm("Are you sure you want to delete this plugin?")) {
            handleDelete(id);
        }
    };
    const handleDelete = async (id) => {
        try {
            const res = await http.delete(`/admin/deletePlugin/${id}`);
            setMsg(res.data.msg);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            fetchData();
        } catch (error) {
            console.error('Error deleting plugin:', error);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editPlugin.id) {
                const res = await http.put(`/admin/updatePlugin/${editPlugin.id}`, editPlugin);
                setMsg(res.data.msg);
                setEditPlugin('');
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
            } else {
                let res = await http.post('/admin/add_plugin', editPlugin);
                setMsg(res.data.msg);
                setEditPlugin('');
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
            }
            setShowModal(false);
            fetchData();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const initializeDataTable = () => {
        $(document).ready(function () {
            $('#example').DataTable();
        });
    };

    return (
        <>
        <div className="container">
    <div className="card">
        <div className="card-header d-flex justify-content-between">
            <div>
                <button type="button" className="btn btn-success" onClick={() => setShowModal(true)}>
                    Add Plugin
                </button>
            </div>
            {showMessage && (
                <div className="alert alert-success text-center" style={{ width: 'fit-content', margin: '0 auto' }}>
                    {msg}
                </div>
            )}
        </div>

        <div className="card-body card-block">
            <div className="col-md-12">
                <div className="table-responsive m-b-40">
                    <table className="table table-data3 table-striped nowrap" id="example" width="100%">
                        <thead>
                            <tr>
                                <th>S.no.</th>
                                <th>Plugin Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {plugins.map((plg, index) => (
                                <tr key={plg.id}>
                                    <td>{index + 1}</td>
                                    <td>{plg.plugin}</td>
                                    <td className="action-cell">
                                        <div className="edit-delete-icons">
                                            <button className="editlink btn btn-success" onClick={() => handleEdit(plg.id)}>
                                                <i className="fa fa-edit edit-icon" aria-hidden="true"></i>
                                            </button>
                                            <button onClick={() => showConfirmationDialog(plg.id)} className="btn btn-danger">
                                                <i className="fa fa-trash delete-icon" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<Modal show={showModal} onHide={handleCloseModal}>
<Modal.Header closeButton>
    <Modal.Title>{editPlugin.id ? 'Edit Plugin' : 'Add Plugin'}</Modal.Title>
</Modal.Header>
<Modal.Body>
    <form id="pluginForm" onSubmit={handleFormSubmit}>
        <div className="form-group">
            <label htmlFor="plugin">Plugin Name</label>
            <input type="text" className="form-control" id="plugin" name="plugin" value={editPlugin.plugin} onChange={(e) => setEditPlugin({ ...editPlugin, plugin: e.target.value })} required />
        </div>
        <input type="hidden" id="pluginId" name="id" value={editPlugin.id} />
    </form>
</Modal.Body>
<Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
        Close
    </Button>
    <Button variant="primary" onClick={handleFormSubmit}>
        {editPlugin.id ? 'Update Plugin' : 'Add Plugin'}
    </Button>
</Modal.Footer>
</Modal>
        </>
    );
};

export default Plugin;
