import React, { useState, useEffect } from 'react';
import AuthUser from '../../../AuthUser';
import $ from 'jquery';
import 'datatables.net';
import { Link } from 'react-router-dom';

function Users() {
    const [data, setData] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const { http } = AuthUser();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/users');
            const responseData = response.data;
            setData(responseData.data);
            initializeDataTable();
        } catch (error) {
            console.error(error);
        }
    };

    const initializeDataTable = () => {
        $(document).ready(function() {
            $('#example').DataTable();
        });
    };

    const toggleStatus = async (userId, currentStatus) => {
        try {
            const newStatus = currentStatus === 1 ? 0 : 1;
            const res= await http.put('/admin/userStatus/'+userId + '/' +newStatus);
            const updatedData = data.map(user => {
                if (user.id === userId) {
                    return { ...user, status: newStatus };
                }
                return user;
            });
            setData(updatedData);
             
        } catch (error) {
            console.error('Error toggling user status:', error);
        }
    };
   
    const deleteUser = async (userId) => {
        try {
            await http.delete('/admin/userDelete/'+userId);
            // Remove deleted user from the list
            setData(data.filter(user => user.id !== userId));
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000); // Display message for 2 seconds
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };
    return (
        
        <div className="container">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div>
                        <Link to="/admin/add-user">
                            <button type="button" className="btn btn-success" data-toggle="modal">
                                Add New User
                            </button>
                        </Link>
                    </div>
                    <div>
                    {showMessage && (
                        <div className="alert alert-success" role="alert">
                            User deleted successfully
                        </div>
                    )}
                   </div>
                    <div className="btn-group" style={{ marginBottom: '20px' }}>
                        <button type="button" className="btn btn-primary">Export</button>
                        <button className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown"></button>
                        <div className="dropdown-menu">
                            <button className="dropdown-item" id="copyButton">Copy</button>
                            <button className="dropdown-item" id="csvButton">CSV</button>
                            <button className="dropdown-item" id="excelButton">Excel</button>
                            <button className="dropdown-item" id="pdfButton">PDF</button>
                            <button className="dropdown-item" id="printButton">Print</button>
                        </div>
                    </div>
                </div>
                <div className="card-body card-block">
                    <div className="col-md-12">
                        <div className="table-responsive m-b-40">
                            <table className="table table-data3 table-striped display nowrap" id="example" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th>User Id</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Joining Date</th>
                                        <th>Designation</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(user => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td><Link to={`/admin/userDetail/view_profile/${user.id}`}>{user.emp_id}</Link></td>
                                            <td>{user.name}</td>
                                            <td>{user.officialEmail}</td>
                                            <td>{user.doj}</td>
                                            <td>{getDepartmentName(user.department)}</td>
                                            <td>
                                                <Link to={`/admin/edit-user/${user.id}`}>
                                                    <button type="button" className="btn btn-success">Edit</button>
                                                </Link>
                                                <button onClick={() => toggleStatus(user.id, user.status)}  
                                                 className={`btn ${user.status === 1 ? 'btn-primary' : 'btn-warning'}`}   style={{ width: '87px', borderRadius: '20px', padding: '5px' }}>
                                                        {user.status === 1 ? 'Activate' : 'Deactivate'}
                                                </button>
                                                <button onClick={() => deleteUser(user.id)} className="btn btn-danger">Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getDepartmentName(department) {
    switch (department) {
        case 1:
            return 'Business Development - Bidding';
        case 2:
            return 'Business Development - Email Marketing';
        case 3:
            return 'Human Resource';
        case 4:
            return 'Website Design and Development';
        case 5:
            return 'Mobile Application';
        case 6:
            return 'Digital Marketing (SEO)';
        case 7:
            return 'Content Writer';
        case 8:
            return 'Business Development - Linkedin';
        default:
            return 'Unknown Department';
    }
}

export default Users;
