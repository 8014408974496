import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import AuthUser from '../../../AuthUser';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LinkBuildingData = () => {
    const [data, setData] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [category, setCategory] = useState([]);
    const [country, setCountry] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const tableRef = useRef(null);
    const { http } = AuthUser();

    const [industryPlaceholderText, setIndustryPlaceholderText] = useState('Select Industry');
    const [countryPlaceholderText, setCountryPlaceholderText] = useState('Select Country');

    const industryOptions = industry.map((list) => ({ value: list.id, label: list.industry, type: 'industry' }));
    const countryOptions = country.map((list) => ({ value: list.id, label: list.country, type: 'country' }));

    const [showModal, setShowModal] = useState(false);
    const [selectedModalIndustry, setSelectedModalIndustry] = useState('');
    const [selectedIndustryItems, setSelectedIndustryItems] = useState([]);
    const [modalSearchText, setModalSearchText] = useState('');
    const [selectedModalCountry, setSelectedModalCountry] = useState('');
    const [selectedModalCategory, setSelectedModalCategory] = useState('');
    const [editId, setEditId] = useState(null);
    const [websiteLink, setWebsiteLink] = useState('');
    const [da, setDa] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/link-building-data');
            setData(response.data.linkBuildingData);
            setIndustry(response.data.industry);
            setCategory(response.data.category);
            setCountry(response.data.country);
            initializeDataTable();
            setLoading(false);
        } catch (error) {
            console.error('Error fetching portfolio data:', error);
        }
    };

    const initializeDataTable = () => {
        $(document).ready(function () {
            if (!$.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable({
                    ordering: false,
                });
            }
        });
    };

    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption.value);
        setIndustryPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Industry');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
            initializeDataTable();
        }
    };

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value);
        setCountryPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Country');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
            initializeDataTable();
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleModalSearchChange = (event) => {
        setModalSearchText(event.target.value.toLowerCase());
    };
    useEffect(() => {
        const selectedIndustryIds = selectedIndustryItems;
        setSelectedModalIndustry(selectedIndustryIds);
        
    }, [selectedIndustryItems]);
    const filteredIndustry = industry.filter((item) =>
        item.industry.toLowerCase().includes(modalSearchText)
    );
    const handleModalCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedIndustryItems(prevSelectedItems => {
            if (checked) {
                return [...prevSelectedItems, value];
            } else {
                return prevSelectedItems.filter(item => item !== value);
            }
        });
    };
    const handleAllCheckboxIndustryChange = (event) => {
        const isChecked = event.target.checked;
        const allIndustryIds = filteredIndustry.map((item) => String(item.id));
        setSelectedIndustryItems(isChecked ? allIndustryIds : []);
    };
    const handleModalCountryChange = (selectedOption) => {
        setSelectedModalCountry(selectedOption);
    };
    const handleModalCategoryChange = (selectedOption) => {
        setSelectedModalCategory(selectedOption);
    };

    const handleEdit = (id) => {
        const linkBuildingId = data.find(item => item.id === id);
        const selectedCate = category.find(cate => cate.id == linkBuildingId.linkCategory);
        const selectedCnt = country.find(Cnt => Cnt.id == linkBuildingId.country);
        setSelectedModalIndustry(linkBuildingId.industry);
        setWebsiteLink(linkBuildingId.website_link);
        setDa(linkBuildingId.da);
        setSelectedModalCountry({ value: selectedCnt.id, label: selectedCnt.country });
        setSelectedModalCategory({ value: selectedCate.id, label: selectedCate.linkCategory });
        setEditId(id);
        setShowModal(true);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!websiteLink) {
                Swal.fire('Error', 'The website link field is required.', 'error');
                return;
            }
    
            const formData = {
                industry: selectedModalIndustry,
                linkCategory: selectedModalCategory,
                country: selectedModalCountry,
                website_link: websiteLink,
                da: da,
                id: editId
            };
            
            if (editId) {
                const response = await http.put(`/admin/edit_linkBuildingData/${editId}`, formData);
                console.log(response)
                const responseData = response.data;
                if (response.status === 200) {
                    Swal.fire('Success', responseData.msg, 'success');
                    setMsg(responseData.msg);
                    setShowMessage(true);
                    setTimeout(() => {
                        setShowMessage(false);
                    }, 5000);
                    setShowModal(false);
                    resetForm();
                    fetchData();
                } else {
                    if (responseData.errors) {
                        const errorMessage = responseData.errors.join('\n');
                        Swal.fire('Error', errorMessage, 'error');
                    } else {
                        Swal.fire('Error', 'An error occurred while updating the portfolio.', 'error');
                    }
                }
            }else {
                const response = await http.post('/admin/add_linkBuildingData', formData);
                console.log(response)
                const responseData = response.data;
            
                
                if (response.status === 200) {
                    Swal.fire('Success', responseData.msg, 'success');
                    setMsg(responseData.msg);
                    setShowMessage(true);
                    setSelectedModalIndustry('');
                    setSelectedModalCategory('');
                    setSelectedModalCountry('');
                    setWebsiteLink('');
                    setDa('');
                    setTimeout(() => {
                        setShowMessage(false);
                    }, 5000);
                    setShowModal(false);
                    resetForm();
                    fetchData();
                } else {
                    // Handle validation errors
                    if (responseData.errors) {
                        const errorMessage = responseData.errors.join('\n');
                        Swal.fire('Error', errorMessage, 'error');
                    } else {
                        Swal.fire('Error', 'An error occurred while submitting the form.', 'error');
                    }
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire('Error', 'An error occurred while submitting the form.', 'error');
        }
    };
    const resetForm = () => {
        setSelectedIndustryItems([]);
        setSelectedModalCategory(null);
        setSelectedModalCountry(null);
        setWebsiteLink('');
        setDa('');
    };
    const showConfirmationDialog = (id) => {
        if (window.confirm("Are you sure you want to delete this Portfolio?")) {
            handleDelete(id);
        }
    };
    const handleDelete = async (id) => {
        try {
            const res = await http.delete(`/admin/deleteLinkBuildingData/${id}`);
            setMsg(res.data.msg);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            Swal.fire('Success', res.data.msg, 'success');
            fetchData();
        } catch (error) {
            console.error('Error deleting country:', error);
        }
    };
    return (
        <>
        {loading ? ( // Show loading message when data is being fetched
                <div>
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
                >
                <CircularProgress color="inherit" />
                </Backdrop>

             </div>
            ) : (
        <div className="container">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div>
                    <button type="button" className="btn btn-success" onClick={() => setShowModal(true)}>
                            Add Link Building Data
                        </button>
                    </div>
                    {showMessage && (
                        <div className="alert alert-success text-center" style={{ width: 'fit-content', margin: '0 auto' }}>
                            {msg}
                        </div>
                    )}
                </div>

                <div className="card-body card-block">
                    <div className="col-md-12">
                        <div className="table-responsive m-b-40">
                        <table className="table table-data3 table-striped nowrap" width="100%" id="example" ref={tableRef}>
                        <thead>
                            <tr>
                            <th>S.no.</th>
                            <th>Category</th>
                            <th>Website</th>
                            <th>DA</th>
                            <th>
                              <Select className="filter"
                                options={[
                                    { value: '', label: 'Select Country', type: 'placeholder' },
                                    ...countryOptions
                                ]}
                                placeholder={countryPlaceholderText}
                                value={selectedCountry} 
                                onChange={handleCountryChange} />
                            </th>
                            <th>
                                <Select 
                                    className="filter" 
                                    options={[
                                        { value: '', label: 'Select Industry', type: 'placeholder' },
                                        ...industryOptions
                                    ]}
                                    placeholder={industryPlaceholderText} // Placeholder text for the search input
                                    value={selectedIndustry} 
                                    onChange={handleIndustryChange} 
                                />
                            </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data
                            .filter((item) => {
                                const industryMatch = selectedIndustry === '' || (item.industry && item.industry.split(',').some(industryId => industryId.trim() === selectedIndustry.toString()));
                                const countryMatch = selectedCountry === '' || item.country.toString() == selectedCountry;
                                return industryMatch  && countryMatch;
                          })
                            
                            .map((list, index) => (
                            <tr key={list.id}>
                                <td>{index + 1}</td>
                                <td>
                                       {category.find(cate => cate.id == list.linkCategory )?.linkCategory  || 'Unknown'}
                                </td>
                                <td>{list.website_link}</td>
                                <td>{list.da}</td>
                                <td>
                                       {country.find(ctry => ctry.id == list.country)?.country || 'Unknown'}
                                </td>
                                <td>
                                    {list.industry.split(',').map((industryId, i) => {
                                        const industryName = industry.find(item => item.id === parseInt(industryId))?.industry;
                                        return industryName;
                                    }).filter(industry => industry).join(', ')}
                                </td>
                                    <td>
                                            <div className="edit-delete-icons">
                                                <button className="editlink btn btn-success" onClick={() => handleEdit(list.id)}>
                                                <i className="fa fa-edit edit-icon" aria-hidden="true"></i>
                                                </button>
                                                <button onClick={() => showConfirmationDialog(list.id)} className="btn btn-danger">
                                                    <i className="fa fa-trash delete-icon" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        <Modal show={showModal} onHide={handleCloseModal}>
    <Modal.Header closeButton>
    <Modal.Title>{editId ? 'Edit Link Building Data' : 'Add Link Building Data'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form id="portfolioForm" onSubmit={handleSubmit}>
        <input type="hidden" id="portfolioId" name="id" value={editId} />
        <div className="form-group">
                <label htmlFor="category">Category</label>
                <Select
                    
                    options={category.map(cate => ({ value: cate.id, label: cate.linkCategory }))}
                    value={selectedModalCategory}
                    onChange={handleModalCategoryChange}
                    isClearable={true}
                    placeholder="Select Category"
                />
            </div>
            <div className="form-group">
                <label htmlFor="websiteLink">Website Link</label>
                <input type="text" className="form-control" id="websiteLink" value={websiteLink} onChange={(e) => setWebsiteLink(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="da">DA</label>
                <input type="text" className="form-control" id="da" value={da} onChange={(e) => setDa(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="country">Country</label>
                <Select
                                options={country.map(ctr => ({ value: ctr.id, label: ctr.country }))}
                                value={selectedModalCountry}
                                onChange={handleModalCountryChange}
                                isClearable={true}
                                placeholder="Select Country"
                            />
            </div>
            <div className="form-group">
            <Dropdown>
                <Dropdown.Toggle variant="secondary" id="industryDropdown" style={{maxWidth: '300px'}}>
                    Select Industry
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    <div className="input-group mb-2">
                        <input
                            type="text"
                            className="form-control"
                            id="industrySearch"
                            placeholder="Search Industry"
                            onChange={handleModalSearchChange}
                        />
                    </div>
                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item">
                        <input
                            type="checkbox"
                            id="industry-all"
                            checked={selectedIndustryItems.length === filteredIndustry.length}
                            onChange={handleAllCheckboxIndustryChange}
                        />
                        <label htmlFor="industry-all">All</label>
                    </div>
                    {filteredIndustry.map((item) => {
                        const industryIds = Array.isArray(selectedModalIndustry) ? selectedModalIndustry : String(selectedModalIndustry).split(','); // Check if selectedIndustry is an array or convert to string and split
                        return (
                            <div className="dropdown-item" key={item.id}>
                                <input
                                    type="checkbox"
                                    className="industry-checkbox"
                                    id={`industry-${item.id}`}
                                    value={item.id}
                                    checked={industryIds.includes(String(item.id))} // Check if the industry ID is included
                                    onChange={handleModalCheckboxChange}
                                />
                                <label htmlFor={`industry-${item.id}`}>&nbsp;{item.industry}</label>
                            </div>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                    Close
                </button>
                <button type="submit" className="btn btn-primary">
                {editId ? 'Update' : 'Submit'}
                </button>
            </div>
        </form>
    </Modal.Body>
            </Modal>
    </>
    );
};

export default LinkBuildingData;
