import React, { useState, useEffect } from 'react';
import AuthUser from '../../AuthUser';
import { Link } from 'react-router-dom';

function Dashboard() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const { http } = AuthUser();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/dashboard');
            setData(response.data);
        } catch (error) {
            console.error(error);
            setError('Error fetching data');
        }
    };

    // Conditional rendering to handle the case when data is null
    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-fluid px-4">
                        <h1 className="mt-4">Dashboard</h1>
                        {/* 
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        */}
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-primary text-white mb-4">
                                    <div className="card-body">Total Number Users</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.allUsers}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-warning text-white mb-4">
                                    <div className="card-body">New  User Register in Month</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.current_users}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-success text-white mb-4">
                                    <div className="card-body">Active User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.activeUsers}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-danger text-white mb-4">
                                    <div className="card-body">In Active User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.inActiveUsers}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-success text-white mb-4">
                                    <div className="card-body">Total Present User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.totalBreakUser}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-danger text-white mb-4">
                                    <div className="card-body">Total Absent User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.totalLunchUser}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-info text-white mb-4">
                                    <div className="card-body">Total break user</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.totalPresentUser}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-info text-white mb-4">
                                    <div className="card-body">Total Lunch User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.totalAbsentUser}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-warning text-white mb-4">
                                    <div className="card-body">Late Login User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.lateLoginUsers}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-danger text-white mb-4">
                                    <div className="card-body">Highest Absent User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.activeUsers}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <div className="card bg-warning text-white mb-4">
                                    <div className="card-body">Monthly Late Login User</div>
                                    <div className="card-footer d-flex align-items-center justify-content-between">
                                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                                        <div className="large text-white"><i className="fas1 fa-angle-right1"></i>{data.activeUsers}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    );
}

export default Dashboard;
