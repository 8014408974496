import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import AuthUser from '../../../AuthUser';

const Logsheet = () => {
    const location = useLocation();
    const { search } = location || {};
    const queryParams = new URLSearchParams(search || '');
    const date = queryParams.get('date');
    const id = queryParams.get('id');
    const { http } = AuthUser();
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = () => {
        http.get(`/admin/report/logsheet/${date}/${id}`)
            .then((res) => {
                console.log(res.data.data);
                setUserData(res.data.data);
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    };
    
    let totalLoginDuration = 0;
    let totalGapDuration = 0;
    return (
        <div className="container">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div>
                        <button type="button" className="btn btn-success" data-toggle="modal">
                                LogSheet
                        </button>
                     </div>
                    <div className="btn-group" style={{ marginBottom: '20px' }}>
                        <Link to="/admin/report">
                            <button type="button" className="btn btn-success" data-toggle="modal">
                                 Back
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="card-body card-block">
                    <div className="col-md-12">
                        <div className="table-responsive m-b-40">
                        <table className="table table-data3 table-striped nowrap" width="100%">
                        <thead>
                          <tr>
                            <th>S.no.</th>
                            <th>Current Status</th>
                            <th>Project Name</th>
                            <th>Time Start</th>
                            <th>Time Out</th>
                            <th>Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Map over the data and generate table rows */}
                          {userData.map((list, index) => {
                            // Calculate the decreasing value of x
                            const x = userData.length - index;
                  
                            // Calculate duration and update total durations
                            const loginTime = new Date(list.login_time);
                            const logoutTime = list.logout_time ? new Date(list.logout_time) : null;
                            const duration = logoutTime ? (logoutTime - loginTime) / 1000 : 0;
                  
                            if (list.current_status === 'Break' || list.current_status === 'Lunch') {
                              totalGapDuration += duration;
                            } else {
                              totalLoginDuration += duration;
                            }
                  
                            return (
                              <tr key={index}>
                                <td>{x}</td>
                                <td>{list.current_status}</td>
                                <td>{list.current_status === 'Project' ? list.projectName : ''}</td>
                                <td>{loginTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
                                <td>{logoutTime ? logoutTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}</td>
                                <td>{formatDuration(duration)}</td>
                              </tr>
                            );
                          })}
                          {/* Display subtotals */}
                          <tr>
                            <td colSpan="5" style={{ fontSize: '16px', fontWeight: 'bold' }}>Sub Total Login Duration:</td>
                            <td>{formatDuration(totalLoginDuration)}</td>
                          </tr>
                          <tr>
                            <td colSpan="5" style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Break and Lunch duration:</td>
                            <td>{formatDuration(totalGapDuration)}</td>
                          </tr>
                          <tr>
                            <td colSpan="5" style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Login Duration:</td>
                            <td>{formatDuration(totalLoginDuration + totalGapDuration)}</td>
                          </tr>
                        </tbody>
                      </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours} hours, ${minutes} minutes`;
  };

export default Logsheet;