
import React from 'react';
import Dashboard from '../components/admin/Dashboard';
import Users from '../components/admin/users/Users';
import EditUser from '../components/admin/users/EditUser';
import AddUser from '../components/admin/users/AddUser';
import Project from '../components/admin/project/Project';
import AddProject from '../components/admin/project/AddProject';
import Attendance from '../components/admin/attendance/Attendance';
import Report from '../components/admin/report/Report';
import Logsheet from '../components/admin/report/Logsheet';
import Technology from '../components/admin/other-technology/Technology';
import Industry from '../components/admin/other-technology/Industry';
import Plugin from '../components/admin/other-technology/Plugin';
import Hosting from '../components/admin/other-technology/Hosting';
import Country from '../components/admin/other-technology/Country';
import Category from '../components/admin/other-technology/Category';

import Portfolio from '../components/admin/portfolio/Portfolio';
import SeoPortfolio from '../components/admin/portfolio/SeoPortfolio';
import LinkBuildingData from '../components/admin/portfolio/LinkBuildingData';
/*const routes = [
    { path: '/admin', exact: true, name: 'Admin' },
    { path: '/admin/dashboard', name: 'Dashboard', element: <Dashboard /> },
    { path: '/admin/project', exact: true, name: 'Project', element: <Project /> },
];
*/
const routes = [
    { path: '/admin', exact: true, name: 'Admin' },
    { path: '/dashboard', name: 'Dashboard', element: <Dashboard /> },
    { path: '/users', name: 'Users', element: <Users /> },
    { path: '/add-user', name: 'AddUser', element: <AddUser /> },
    { path: "/edit-user/:id", name: 'EditUser', element: <EditUser /> },

   
    { path: '/project', name: 'Project', element: <Project /> },
    { path: '/add-project', name: 'AddProject', element: <AddProject /> },

    { path: '/attendance', name: 'Attendance', element: <Attendance /> },
    { path: '/report', name: 'Report', element: <Report /> },
    { path: '/logsheet', name: 'Logsheet', element: <Logsheet /> },


    { path: '/technology', name: 'Technology', element: <Technology /> },
    { path: '/industry', name: 'Industry', element: <Industry /> },
    { path: '/plugin', name: 'Plugin', element: <Plugin /> },
    { path: '/hosting', name: 'Hosting', element: <Hosting /> },
    { path: '/country', name: 'Country', element: <Country /> },
    { path: '/category', name: 'Category', element: <Category /> },

    { path: '/portfolio', name: 'Portfolio', element: <Portfolio /> },

    { path: '/seo-portfolio', name: 'SeoPortfolio', element: <SeoPortfolio /> },
    { path: '/link-building-data', name: 'LinkBuildingData', element: <LinkBuildingData /> },
    
    
];

export default routes;