import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AuthUser from '../../../AuthUser';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function EditUser() {
    const [inputs,setInputs] = useState({});
    const [image, setImage] = useState(null);
    const { http } = AuthUser();
    const [showMessage, setShowMessage] = useState(false);

    const { id } = useParams();
    
    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Get the first file from the input
        setImage(file); // Set the file to the image state
    }

    
    useEffect(()=>{
        fetchUser()
    },[]);

    const fetchUser= () =>{
        http.get('/admin/edit-user/'+id).then((res)=>{
            console.log(res.data.data)
            setInputs({
                photo:res.data.data.photo,
                name:res.data.data.name,
                email:res.data.data.email,
                current_address:res.data.data.current_address,
                f_name:res.data.data.f_name,
                m_name:res.data.data.m_name,
                type_user:res.data.data.type_user,
                department:res.data.data.department,
                emp_id:res.data.data.emp_id,
                officialEmail:res.data.data.officialEmail,
                mobileNo:res.data.data.mobileNo,
                permanent_address:res.data.data.permanent_address,
                f_mobileNo:res.data.data.f_mobileNo,
                m_mobileNo:res.data.data.m_mobileNo,
                dob:res.data.data.dob,
                doj:res.data.data.doj,
                qualification_graduate:res.data.data.qualification_graduate,
                qualification_postgraduate:res.data.data.qualification_postgraduate,
                total_work_exp:res.data.data.total_work_exp,
                last_company_name:res.data.data.last_company_name,
                pre_company_name:res.data.data.pre_company_name,
                past_company_record:res.data.data.past_company_record,
                aadhar_card:res.data.data.aadhar_card,
                pan_card:res.data.data.pan_card,
                current_address_proof:res.data.data.current_address_proof,
                permanent_address_proof:res.data.data.permanent_address_proof,
                phone_no_proof:res.data.data.phone_no_proof,
               

            });
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const { checked, type } = event.target;
        const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
        setInputs(prevValues => ({
            ...prevValues,
            [name]: newValue
        }));
    }

   const handleSubmit = async (e) => {
        e.preventDefault();
         const formData = new FormData();
        formData.append('photo', image);
        Object.keys(inputs).forEach(key => {
            formData.append(key, inputs[key]);
        });
       try {
            const response = await fetch('http://127.0.0.1:8000/api/admin/update-user/'+id, {
                method: 'POST',
                body: formData
            });
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 2000); 
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <div className="container">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div>
                        <button type="button" className="btn btn-success" data-toggle="modal">
                            Edit User
                        </button>
                    </div>
                    <div>
                    {showMessage && (
                        <div className="alert alert-success" role="alert">
                            User Updated successfully
                        </div>
                    )}
                   </div>
                    <div className="btn-group" style={{ marginBottom: '20px' }}>
                        <Link to="/admin/users">
                            <button type="button" className="btn btn-success" data-toggle="modal">
                                User List
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="card-body card-block">
                <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-4">
                            <div className="form-group">
                            <label htmlFor="projectName1" className="form-control-label">Add Profile Photo</label>
                            <img 
                                id="uploaded-image1" 
                                className="rounded-circle1 img-responsive1" 
                                src={inputs.photo ? "http://127.0.0.1:8000/storage/media/users/" + inputs.photo : image}
                                alt="Uploaded Image" 
                                style={{ 
                                    display: image ? 'none' : 'block',
                                    height: '192px', 
                                    width: '280px'   
                                }} 
                            />
                            <img 
                                id="uploaded-image2" 
                                className="rounded-circle1 img-responsive1" 
                                src={image ? URL.createObjectURL(image) : ''}
                                alt="Uploaded Image" 
                                style={{ 
                                    display: image ? 'block' : 'none',
                                    height: '192px', 
                                    width: '280px'   
                                }} 
                            />
                            <input type="file" name="photo" id="photo-input" onChange={handleImageChange} />
                        </div>
                                <div className="form-group">
                                    <label htmlFor="name" className="form-control-label">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Enter Name"
                                        value={inputs.name || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-control-label">Personal Email</label>
                                    <input type="text" id="email" name="email" className="form-control"  placeholder="Enter Email.."
                                    value={inputs.email || ''}
                                    onChange={handleChange}
                                     />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="gender" className="form-control-label">Gender</label>
                                    <select name="gender" className="form-control" >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="current_address" className="form-control-label">Present Address</label>
                                    <input type="text" id="current_address" name="current_address" className="form-control"  
                                    value={inputs.current_address || ''}
                                    onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="f_name" className="form-control-label">Father Name</label>
                                    <input type="text" id="f_name" name="f_name" className="form-control"
                                    value={inputs.f_name || ''}
                                    onChange={handleChange}
                                      />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="m_name" className="form-control-label">Mother Name</label>
                                    <input type="text" id="m_name" name="m_name" className="form-control"
                                    value={inputs.m_name || ''}
                                    onChange={handleChange}
                                     />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                <label htmlFor="type_user" className="form-control-label">Users Type</label>
                                    <select className="form-control" name="type_user" value={inputs.type_user || ''} onChange={handleChange}>
                                        <option>Select type of user</option>
                                        <option value="1">Sub-Admin</option>
                                        <option value="2">Sr Executive</option>
                                        <option value="3">Executive</option>
                                        <option value="4">Trainee</option>
                                    </select>
                               </div>
                                <div className="form-group">
                                    <label htmlFor="department" className="form-control-label">Position</label>
                                    <select className="form-control" name="department" value={inputs.department || ''} onChange={handleChange}>
                                        <option>Select Position</option>
                                        <option value="1" >Business Development - Bidding</option>
                                        <option value="2" >Business Development - Email Marketing</option>
                                        <option value="3" >Human Resource</option>
                                        <option value="4" >Website Design and Development</option>
                                        <option value="5" >Mobile Application</option>
                                        <option value="6" >Digital Marketing (SEO)</option>
                                        <option value="7" >Content Writer</option>
                                        <option value="8" >Business Development - Linkedin</option>
                                        <option value="9" >Admin</option>
                                    </select>
                                </div>
                            <div className="form-group">
                                <label htmlFor="emp_id" className="form-control-label">Add Employee Id</label>
                                <input type="text" id="emp_id" name="emp_id" className="form-control" 
                                value={inputs.emp_id || ''}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="officialEmail" className="form-control-label">Official Email</label>
                                <input type="text" id="officialEmail" name="officialEmail"  className="form-control" 
                                value={inputs.officialEmail || ''}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobileNo" className="form-control-label">Mobile Number</label>
                                <input type="text" id="mobileNo" name="mobileNo" className="form-control" 
                                value={inputs.mobileNo || ''}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="permanent_address" className="form-control-label">Permanent Address</label>
                                <input type="text" id="permanent_address" name="permanent_address"  className="form-control"
                                value={inputs.permanent_address || ''}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="f_mobileNo" className="form-control-label">Father/Husband Phone no</label>
                                <input type="text" id="f_mobileNo" name="f_mobileNo"  className="form-control" 
                                value={inputs.f_mobileNo || ''}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="m_mobileNo" className="form-control-label">Mother/Wife Phone no</label>
                                <input type="text" id="m_mobileNo" name="m_mobileNo"  className="form-control"
                                value={inputs.m_mobileNo || ''}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="form-control-label">Password</label>
                                <input type="password" id="password" name="password" className="form-control" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="dob" className="form-control-label">Date of Birth</label>
                            <DatePicker
                                id="dob"
                                name="dob"
                                className="form-control"
                                value={inputs.dob || ''}
                                onChange={handleChange}
                                dateFormat="yyyy-MM-dd" 
                                showYearDropdown 
                                scrollableYearDropdown 
                                yearDropdownItemNumber={100} 
                            />
                        </div>
                            </div>
                            <div className="col-md-4">
                            <div className="form-group">
                            <label htmlFor="doj" className="form-control-label">Date of Joining</label>
                            <input type="date" id="doj" name="doj"  className="form-control"
                            value={inputs.doj || ''}
                            onChange={handleChange}
                             />
                        </div>
                        <div className="form-group">
                            <label htmlFor="qualification_graduate" className="form-control-label">Graduate</label>
                            <input type="text" id="qualification_graduate" name="qualification_graduate" className="form-control" 
                            value={inputs.qualification_graduate || ''}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="qualification_postgraduate" className="form-control-label">Post Graduate</label>
                            <input type="text" id="qualification_postgraduate" name="qualification_postgraduate"  className="form-control" 
                            value={inputs.qualification_postgraduate || ''}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="total-work-exp" className="form-control-label">Employee Pre Company</label>
                            <div className="work-experience-group">
                                <div className="input-wrapper">
                                    <label htmlFor="total-work-exp">Total Exp</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="total-work-exp" name="total_work_exp" 
                                        value={inputs.total_work_exp || ''}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="last-company-name">Last Company Name</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="last-company-name" name="last_company_name" 
                                        value={inputs.last_company_name || ''}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="pre-company-name">Pre Company Name</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="pre-company-name" name="pre_company_name" 
                                        value={inputs.pre_company_name || ''}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                                    <label htmlFor="document-collect" className="form-control-label">Document Collect</label>
                                    <div className="document-group">
                                        <div>
                                            <label className="switch">
                                            <input 
                                            type="checkbox" 
                                            id="past-company-toggle" 
                                            name="past_company_record" 
                                            value='1'
                                            checked={inputs.past_company_record == 1} 
                                            onChange={handleChange}
                                        />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="past-company-toggle" style={{ lineHeight: "30px" }}>Past Company Record</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="aadhar-card-toggle" className="toggle-input" name="aadhar_card"
                                                 value="1" 
                                                 checked={inputs.aadhar_card == 1} 
                                                  onChange={handleChange}
                                                 />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="aadhar-card-toggle" style={{ lineHeight: "30px" }}>Aadhar Card</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="pan-card-toggle" className="toggle-input" name="pan_card" 
                                                value="1" 
                                                checked={inputs.pan_card == 1} 
                                                onChange={handleChange}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="pan-card-toggle" style={{ lineHeight: "30px" }}>Pan Card</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="current-address-toggle" className="toggle-input" name="current_address_proof" 
                                                 value="1" 
                                                 checked={inputs.current_address_proof == 1} 
                                                 onChange={handleChange}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="current-address-toggle" style={{ lineHeight: "30px" }}>Current Address Proof</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="permanent-address-toggle" className="toggle-input" name="permanent_address_proof" 
                                                 value="1" 
                                                 checked={inputs.permanent_address_proof == 1} 
                                                 onChange={handleChange}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="permanent-address-toggle" style={{ lineHeight: "30px" }}>Permanent Address Proof</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="phone-number-toggle" className="toggle-input" name="phone_no_proof" 
                                                value="1" 
                                                checked={inputs.phone_no_proof == 1} 
                                                onChange={handleChange}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="phone-number-toggle" style={{ lineHeight: "30px" }}>Phone Number Proof</label>
                                        </div>
                                    </div>
                                </div>

                        
                            </div>
                        </div>
                        <div className="card-footer">
                            <input type="submit" value="Submit" name="submit" className="btn btn-primary btn-sm" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditUser;
