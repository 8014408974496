import React, { useState, useEffect } from 'react';
import AuthUser from '../../../AuthUser';
import { Link } from 'react-router-dom';

function Project() {
    const [data, setData] = useState([]);
    const [messageDisplayed, setMessageDisplayed] = useState(false);
    const { http } = AuthUser();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/project');
            const responseData = response.data;
            setData(responseData.data);
        } catch (error) {
            console.error(error);
        }
    };

    let totalLoginDuration = 0;
    let totalGapDuration = 0;

    return (
        <div className="container UserMediaQuery">
            <h1 className="mt-4">Project</h1>
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div>
                    <Link to="/admin/add-project">
                        <button type="button" className="btn btn-success" data-toggle="modal" data-target="#projectTaskModal">
                            Add Task
                        </button>
                        </Link>
                    </div>
                </div>
                <div className="card-body card-block">
                    <div className="col-md-12">
                        <div className="table-responsive m-b-40">
                            <table className="table table-data3 table-striped display nowrap" width="100%">
                                <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th>Current Status</th>
                                        <th>Project Name</th>
                                        <th>Time Start</th>
                                        <th>Time Out</th>
                                        <th>Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((list, index) => {
                                        const x = data.length - index;
                                        const loginTime = new Date(list.login_time);
                                        let logoutTime = list.logout_time ? new Date(list.logout_time) : new Date(); 
                                        const logoutTime1 = list.logout_time ? new Date(list.logout_time) : null;
                                        const duration = (logoutTime - loginTime) / 1000;
                              
                                        if (list.current_status === 'Break' || list.current_status === 'Lunch') {
                                          totalGapDuration += duration;
                                        } else {
                                          totalLoginDuration += duration;
                                        }

                                        return (
                                            <tr key={index}>
                                                <td>{x}</td>
                                                <td>{list.current_status}</td>
                                                <td>{list.current_status === 'Project' ? list.projectName : ''}</td>
                                                <td>{loginTime.getHours()}:{loginTime.getMinutes()}</td>
                                                <td>{logoutTime1 ? `${logoutTime1.getHours()}:${logoutTime1.getMinutes()}` : ''}</td>
                                                <td>{formatDuration(duration)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan="5" style={{ fontSize: '16px', fontWeight: 'bold' }}>Sub Total Login Duration:</td>
                                        <td>{formatDuration(totalLoginDuration)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Break and Lunch duration:</td>
                                        <td>{formatDuration(totalGapDuration)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" style={{ fontSize: '16px', fontWeight: 'bold' }}>Total Login Duration:</td>
                                        <td>{formatDuration(totalLoginDuration + totalGapDuration)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {messageDisplayed && (
                                <h6 className="marginTop" id="notification">
                                    <p style={{ color: '#c34244', textTransform: 'uppercase', letterSpacing: '1px', textShadow: '0px 1px #b1b1b1', paddingTop: '5px' }}>
                                        You've gone over your break/lunch time. Please adjust your next break to accommodate this time or adjust your scheduled login hours for today accordingly.
                                    </p>
                                </h6>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours} hours, ${minutes} minutes`;
};

export default Project;
