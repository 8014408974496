import React, { useState, useEffect } from 'react';
import '../../../assets/admin/css/customcalender.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock , faMobile } from '@fortawesome/free-solid-svg-icons';
import AuthUser from '../../../AuthUser';
import { useNavigate } from 'react-router-dom';

const ReportPage = () => {
    // Define state variables
    const [attendanceData, setAttendanceData] = useState([]);
    const [holiday, setHoliday] = useState([]);
    const [profile, setProfile] = useState([]);
    const [profileId, setProfileId] = useState('');
    const [userDetail, setUserDetail] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const { http} = AuthUser();
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch attendance data and other necessary data
        const fetchData = async () => {
            try {
                // Fetch data using Axios
                const response = await http.get(`/admin/report?email=${profileId}`);
                const data = response.data;
                console.log(data)
                // Update state variables
                setProfile(data.userProfile);
                setAttendanceData(data.data1);
                setUserDetail(data.userDetail);
                setUserStatus(data.userStatus);
                setHoliday(data.holiday);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call fetchData function
    }, [profileId]);

    // Construct the image source URL
    const imageUrl = userDetail ? `http://127.0.0.1:8000/storage/media/users/${userDetail.photo}` : '';

    const handleProfileChange = (event) => {
        setProfileId(event.target.value);
    };
    
    const handleDateClick = (info) => {
           
        const clickedDate = info.date;
        const timezoneOffset = clickedDate.getTimezoneOffset(); // Get the time zone offset in minutes
        const adjustedDate = new Date(clickedDate.getTime() - (timezoneOffset * 60000)); // Adjust the date based on the time zone offset
        const formattedDate = `${adjustedDate.getFullYear()}-${String(adjustedDate.getMonth() + 1).padStart(2, '0')}-${String(adjustedDate.getDate()).padStart(2, '0')}`;
        if (profileId) {
            navigate(`/admin/logsheet?date=${formattedDate}&id=${userDetail.id}`);
        } else {
            navigate(`/admin/logsheet?date=${formattedDate}`);
        }
    };
   
    return (
        <div className="container">
            {/* Profile selection dropdown */}
            <div className="card height">
                <div className="row">
                    <div className="col-4">
                        <label htmlFor="profile">Select Profile:</label>
                        <select className="form-control select2" id="profile_id" name="profile_id" onChange={handleProfileChange}>
                            <option value=''>Select Name</option>
                            {profile.map((list) => (
                                <option key={list.id} value={list.id}>
                                    {list.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-3">
                        <div>
                            {userDetail && (
                                <img
                                    id="profile-image"
                                    className="rounded-circle1 img-responsive1"
                                    src={imageUrl}
                                    alt="Profile Image"
                                    style={{ width: '220px', height: '175px' }}
                                />
                            )}
                        </div>
                     </div>
                     <div className="col-3">
                            {userDetail && (
                                <div className="content">
                                    <p>{userDetail.name}</p>
                                    <p>{userDetail.officialEmail}</p>
                                    <p>
                                        <FontAwesomeIcon icon={faClock} />
                                        {new Date().toLocaleTimeString()} <br />
                                        {userStatus.current_status === "Break" || userStatus.current_status === "Lunch" ? (
                                            <>
                                                <i className="fa fa-circle" style={{ color: 'orange' }}></i>
                                                <span>{userStatus.current_status} still Outside</span>
                                            </>
                                        ) : (
                                            <span>
                                                {userDetail.current_status === 1 ? (
                                                    <>
                                                        <i className="fa fa-circle" style={{ color: 'green' }}></i> Project still Inside
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="fa fa-circle" style={{ color: 'black' }}></i> Log Out
                                                    </>
                                                )}
                                            </span>
                                        )}
                                    </p>
                                    <p>
                                    <FontAwesomeIcon icon={faMobile} /> <span>{userDetail.mobileNo}</span>
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="col-2">
                            {userStatus && (
                                <>
                                    <label htmlFor="nf-email" className="form-control-label font-weight-bold">current Status : </label>
                                    <span>{userStatus.current_status}</span>
                                </>
                            )}
                        </div>
                </div>
            </div>
             
            {/* Conditional rendering of FullCalendar */}
            {attendanceData.length > 0 && (
                <div className="row">
                <style>
                {`
                .fc a {
                    color: #8e9195!important;
                    text-decoration: none!important;
                    font-size: 20px!important;
                  }
                    
                `}
            </style>
                    <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        initialView="dayGridMonth"
                        events={attendanceData.map(data => ({
                            title: data.status,
                            start: new Date(data.date + 'T09:00:00'),
                            hours: data.hours,
                            status: data.status,
                            start_time: data.status === 'Absent' ? null : new Date(data.start),
                            end_time: data.status === 'Absent' ? null : new Date(data.end)
                        }))}
                        eventContent={(info) => {
                            // Extract event details
                            const eventHours = info.event.extendedProps.hours;
                            const status = info.event.extendedProps.status;
                            const startTime = info.event.extendedProps.start_time;
                            const endTime = info.event.extendedProps.end_time;
                            const statusClass = 'status-' + status.toLowerCase().replace(' ', '');
                        
                            // Prepare the HTML content based on event details
                            let htmlContent = `<div class="fc-event-dot ${statusClass}"></div>`;
                            if (eventHours !== 0) {
                                htmlContent += `<span class="fc-time" data-hours="${eventHours}">${eventHours} hour(s)</span>`;
                            } else {
                                htmlContent += `<span class="fc-time" data-hours="${eventHours}"></span>`;
                            }
                            htmlContent += '<div class="fc-tooltip">';
                            if (status === 'Absent') {
                                htmlContent += '<p>Absent</p>';
                            } else if (status === 'Sunday') {
                                htmlContent += '<p class="status-text1" style="color: orange; font-size: 15px;"><strong>SUNDAY</strong></p>';
                            }else if (holiday.includes(status)) {
	                            htmlContent += '<p class="status-text" style="color: orange; font-size: 15px;"><strong>' + status.toUpperCase() + '</strong></p>';
                            }
                             else {
                                htmlContent += `<p>In: ${startTime ? new Date(startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '-'}</p>`;
                                htmlContent += `<p>Out: ${endTime && endTime !== '05:30 AM' ? new Date(endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '-'}</p>`;
                            }
                            htmlContent += '</div>';
                        
                            // Return the HTML content as JSX
                            return {
                                html: htmlContent,
                            };
                        }}
                        dateClick={handleDateClick}
                    />
                </div>
            )}
        </div>
    );
};

export default ReportPage;
