import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthUser() {
    const navigate = useNavigate();
    
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () => {
        const userString = sessionStorage.getItem('user');
        const user_detail = JSON.parse(userString);
        return user_detail;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    const saveToken = (user, token) => {
        sessionStorage.setItem('token', JSON.stringify(token));
        sessionStorage.setItem('user', JSON.stringify(user));
        setToken(token);
        setUser(user);
        navigate('/admin/dashboard');
    }

    const logout = () => {
        sessionStorage.clear();
        navigate('/adminLogin');
    }
    const getUserId = async (id) => {
        try {
            const response = await http.get(`admin/getUserId1/${id}`);
            return response;
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error; // Rethrow the error to handle it in the calling code
        }
    }
       // Define the default base URL for live server
        let baseURL = "https://emsapi.spotcodes.in/emsapi/api";

        // Check if the environment is development, then use local server
        if (process.env.NODE_ENV === 'development') {
            baseURL = "http://localhost:8000/api";
        }
    const http = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-type": "application/json"
        }
    });

    return {
        setToken: saveToken,
        token,
        user,
        getToken,
        http,
        logout,
        getUserId
    }
}
