import React, { useState } from 'react';
import AuthUser from './AuthUser';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { http,setToken, setUser } = AuthUser();
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        http.post('/adminLogin', { email: email, password: password })
            .then((res) => {
                setToken(res.data.user,res.data.access_token);
                setUser(res.data.user,res.data.access_token);
            })
            .catch((error) => {
                if (error.response && error.response.status === 500) {
                    setMessage('Server is down. Please try again later.');
                }
            });
     }

    return (
        <div className="bg-primary">
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header"><h3 className="text-center font-weight-light my-4">Login</h3></div>
                                        <div className="card-body">
                                        {message && (
                                            <div className="alert alert-danger" role="alert">
                                                {message}
                                            </div>
                                        )}
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-floating mb-3">
                                                    <input className="form-control" id="inputEmail" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                    <label htmlFor="inputEmail">Email address</label>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input className="form-control" id="inputPassword" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                    <label htmlFor="inputPassword">Password</label>
                                                </div>
                                                <div className="form-check mb-3">
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                                    
                                                    <button className="btn btn-primary" type="submit">Login</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div id="layoutAuthentication_footer">
                    <footer className="py-4 bg-light mt-auto">
                        <div className="container-fluid px-4">
                            <div className="d-flex align-items-center justify-content-between small">
                                <div className="text-muted">Copyright &copy; Your Website 2024</div>
                                <div>
                                    <h1 style={{ fontSize: '18px', color: '#333', marginTop: '10px' }}>Developed By: Basant Kumar</h1>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Login;
