import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () =>{
    return(
        <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted">Copyright &copy; Your Website 2024</div>
                    <div>
                    <h1 style={{ fontSize: '18px', color: '#333', marginTop: '10px' }}>Developed By: Basant Kumar</h1>
                     </div>
                </div>
            </div>
        </footer>

    );
}
export default Footer;