import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthUser from '../../../AuthUser';

function AddProject() {
    const { http } = AuthUser();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        current_status: '',
        projectName: '',
        taskDescription: ''
    });
    const [showFields, setShowFields] = useState(true);

    const handleChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, current_status: value });
        setShowFields(value === 'Project');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let postData = { ...formData };
            if (formData.current_status !== 'Project') {
                postData.projectName = ''; 
                postData.taskDescription = ''; 
            }
            const response = await http.post('/admin/add-project', postData); 
            console.log(response);
            if (response.data.message === 'Login') {
                console.log('Project added successfully!');
                resetForm();
                navigate('/admin/project');
            } else if(response.data.message === 'Break'){
                console.log('Break added successfully!');
                resetForm();
                navigate('/adminLogin');
            }
            else {
                console.log('Logout successfully!');
                resetForm();
                navigate('/adminLogin');
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error (e.g., display error message)
        }
    };

    const resetForm = () => {
        setFormData({
            current_status: '',
            projectName: '',
            taskDescription: ''
        });
    };

    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4">Add Project</h1>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item active">Add Project</li>
            </ol>
            <div className="row">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="current_status">Current Status:</label>
                        <select
                            className="form-control"
                            id="current_status"
                            name="current_status"
                            value={formData.current_status}
                            onChange={handleChange}
                        >
                            <option>Select Option</option>
                            <option value="Project">Project</option>
                            <option value="Break">Break</option>
                            <option value="Lunch">Lunch</option>
                            <option value="Logout">Logout</option>
                        </select>
                    </div>
                    {showFields && (
                        <>
                            <div className="form-group" id="projectNameField">
                                <label htmlFor="projectName" className="form-control-label">Project Name</label>
                                <input
                                    type="text"
                                    id="projectName"
                                    name="projectName"
                                    value={formData.projectName}
                                    onChange={(e) => setFormData({ ...formData, projectName: e.target.value })}
                                    placeholder="Enter Project Name.."
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group" id="taskDescriptionField">
                                <label htmlFor="taskDescription">Task Description</label>
                                <textarea
                                    className="form-control"
                                    id="taskDescription"
                                    name="taskDescription"
                                    value={formData.taskDescription}
                                    onChange={(e) => setFormData({ ...formData, taskDescription: e.target.value })}
                                    rows="3"
                                ></textarea>
                            </div>
                        </>
                    )}
                    <div id="thank_you_msg" className="field_error form-group"></div>
                    <Link to="/admin/project">Back project</Link>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal1" id="cancelButton">Close</button>
                        <button type="submit" className="btn btn-primary" id="publishButton">Project</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProject;
