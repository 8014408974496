import React, { useState, useEffect } from 'react';
import AuthUser from '../../../AuthUser';
import { Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import 'datatables.net';

const Industry = () => {
    const [industries, setIndustries] = useState([]);
    const [editIndustry, setEditIndustry] = useState({ id: '', industry: '' });
    const { http } = AuthUser();
    const [showMessage, setShowMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [msg, setMsg] = useState([]);
    const [industry, setIndustry] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/industry');
            console.log(response.data.ind);
            setIndustries(response.data.ind);
            initializeDataTable();
        } catch (error) {
            console.error('Error fetching industries:', error);
        }
    };

    const handleEdit = (id) => {
        const industryToEdit = industries.find(ind => ind.id === id);
        setEditIndustry({ id: industryToEdit.id, industry: industryToEdit.industry });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const showConfirmationDialog = (id) => {
        if (window.confirm("Are you sure you want to delete this industry?")) {
            handleDelete(id);
        }
    };
    const handleDelete = async (id) => {
        try {
            const res = await http.delete(`/admin/deleteIndustry/${id}`);
            setMsg(res.data.msg);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            fetchData();
        } catch (error) {
            console.error('Error deleting industry:', error);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            if (editIndustry.id) {
                const res = await http.put(`/admin/updateIndustry/${editIndustry.id}`, editIndustry);
                setMsg(res.data.msg);
                setEditIndustry('');
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
            } else {
                let res = await http.post('/admin/add_industry', editIndustry);
                setMsg(res.data.msg);
                setEditIndustry('');
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
            }
            setShowModal(false);
            fetchData();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const initializeDataTable = () => {
        $(document).ready(function () {
            $('#example').DataTable();
        });
    };

    return (
        <div>
            <div className="container">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <div>
                            <button type="button" className="btn btn-success" onClick={() => setShowModal(true)}>
                                Add Industry
                            </button>
                        </div>
                        {showMessage && (
                            <div className="alert alert-success text-center" style={{ width: 'fit-content', margin: '0 auto' }}>
                                {msg}
                            </div>
                        )}
                    </div>

                    <div className="card-body card-block">
                        <div className="col-md-12">
                            <div className="table-responsive m-b-40">
                                <table className="table table-data3 table-striped nowrap" id="example" width="100%">
                                    <thead>
                                        <tr>
                                            <th>S.no.</th>
                                            <th>Industry Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {industries.map((ind, index) => (
                                            <tr key={ind.id}>
                                                <td>{index + 1}</td>
                                                <td>{ind.industry}</td>
                                                <td className="action-cell">
                                                    <div className="edit-delete-icons">
                                                        <button className="editlink btn btn-success" onClick={() => handleEdit(ind.id)}>
                                                            <i className="fa fa-edit edit-icon" aria-hidden="true"></i>
                                                        </button>
                                                        <button onClick={() => showConfirmationDialog(ind.id)} className="btn btn-danger">
                                                            <i className="fa fa-trash delete-icon" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndustry.id ? 'Edit Industry' : 'Add Industry'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="industryForm" onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="industry">Industry Name</label>
                            <input type="text" className="form-control" id="industry" name="industry" value={editIndustry.industry} onChange={(e) => setEditIndustry({ ...editIndustry, industry: e.target.value })} required />
                        </div>
                        <input type="hidden" id="industryId" name="id" value={editIndustry.id} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFormSubmit}>
                        {editIndustry.id ? 'Update Industry' : 'Add Industry'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Industry;
