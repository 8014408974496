import React, { useState, useEffect, useRef, useMemo  } from 'react';
import Select from 'react-select';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import JoditEditor from 'jodit-react';
import he from 'he';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css'; 
import AuthUser from '../../../AuthUser';

const SeoPortfolio = () => {
    const [data, setData] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [technology, setTechnology] = useState([]);
    const [selectedTechnology, setSelectedTechnology] = useState('');
    const [country, setCountry] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const tableRef = useRef(null);
    const { http } = AuthUser();
    const [industryPlaceholderText, setIndustryPlaceholderText] = useState('Select Industry');
    const [technologyPlaceholderText, setTechnologyPlaceholderText] = useState('Select Technology');
    const [countryPlaceholderText, setCountryPlaceholderText] = useState('Select Country');


    const industryOptions = industry.map((list) => ({ value: list.id, label: list.industry, type: 'industry' }));
    const technologyOptions = technology.map((list) => ({ value: list.id, label: list.technology, type: 'technology' }));
    const countryOptions = country.map((list) => ({ value: list.id, label: list.country, type: 'country' }));

    const [showModal, setShowModal] = useState(false);
    const [selectedModalIndustry, setSelectedModalIndustry] = useState('');
    const [selectedIndustryItems, setSelectedIndustryItems] = useState([]);
    const [modalSearchText, setModalSearchText] = useState('');
    const [selectedModalCountry, setSelectedModalCountry] = useState('');
    const [selectedModalTechnology, setSelectedModalTechnology] = useState('');
    const editor = useRef(null);
	const [content, setContent] = useState('');
    const [editId, setEditId] = useState(null);
    const [websiteLink, setWebsiteLink] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true); 
    useEffect(() => {
        fetchData();
    }, []);
// Update selectedIndustry based on selectedItems

    const fetchData = async () => {
        try {
            const response = await http.get('/admin/seo-portfolio');
            setData(response.data.seoPortfolio);
            setIndustry(response.data.industry);
            setTechnology(response.data.technology);
            setCountry(response.data.country);
            initializeDataTable();
            setLoading(false);
        } catch (error) {
            console.error('Error fetching portfolio data:', error);
        }
    };
    const initializeDataTable = () => {
        $(document).ready(function () {
            // Check if DataTable is already initialized on the table element
            if (!$.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable({
                    ordering: false, // Disable sorting
                    
                });
            }
        });
    };
    useEffect(() => {
        const selectedIndustryIds = selectedIndustryItems;
        setSelectedModalIndustry(selectedIndustryIds);
        
    }, [selectedIndustryItems]);
    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption.value);
        setIndustryPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Industry');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
        }
        initializeDataTable();
    };
  
    const handleTechnologyChange = (selectedOption) => {
        setSelectedTechnology(selectedOption.value);
        setTechnologyPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Technology');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
        }
        initializeDataTable();
    };
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value);
        setCountryPlaceholderText(selectedOption ? `${selectedOption.label}` : 'Select Country');
        if (tableRef.current) {
            $(tableRef.current).DataTable().destroy();
        }
        initializeDataTable();
    };

    const handleCloseModal = () => {
        setShowModal(false); 
        
    };
    const handleModalSearchChange = (event) => {
        setModalSearchText(event.target.value.toLowerCase());
    };
    const filteredIndustry = industry.filter((item) =>
        item.industry.toLowerCase().includes(modalSearchText)
    );
    const handleModalCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedIndustryItems(prevSelectedItems => {
            if (checked) {
                return [...prevSelectedItems, value];
            } else {
                return prevSelectedItems.filter(item => item !== value);
            }
        });
    };
    const handleAllCheckboxIndustryChange = (event) => {
        const isChecked = event.target.checked;
        const allIndustryIds = filteredIndustry.map((item) => String(item.id));
        setSelectedIndustryItems(isChecked ? allIndustryIds : []);
    };
    const handleModalCountryChange = (selectedOption) => {
        setSelectedModalCountry(selectedOption);
    };
    const handleModalTechnologyChange = (selectedOption) => {
        setSelectedModalTechnology(selectedOption);
    };
    const handleEdit = (portfolioId) => {
        const portfolioItemId = data.find(item => item.id === portfolioId);
        const selectedTech = technology.find(tech => tech.id == portfolioItemId.technology);
        const selectedCnt = country.find(Cnt => Cnt.id == portfolioItemId.country);
        setSelectedModalIndustry(portfolioItemId.industry);
        setWebsiteLink(portfolioItemId.website_link);
        setSelectedModalCountry({ value: selectedCnt.id, label: selectedCnt.country });
        setSelectedModalTechnology({ value: selectedTech.id, label: selectedTech.technology });
        setContent(portfolioItemId.keywordRanking);
        setEditId(portfolioId);
        setShowModal(true);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!websiteLink) {
                Swal.fire('Error', 'The website link field is required.', 'error');
                return;
            }
    
            const formData = {
                industry: selectedModalIndustry,
                technology: selectedModalTechnology,
                country: selectedModalCountry,
                website_link: websiteLink,
                keywordRanking: content,
                id: editId
            };
            
            if (editId) {
                const response = await http.put(`/admin/edit_seoPortfolio/${editId}`, formData);
                console.log(response)
                const responseData = response.data;
                if (response.status === 200) {
                    Swal.fire('Success', responseData.msg, 'success');
                    setMsg(responseData.msg);
                    setShowMessage(true);
                    setTimeout(() => {
                        setShowMessage(false);
                    }, 5000);
                    setShowModal(false);
                    resetForm();
                    fetchData();
                } else {
                    if (responseData.errors) {
                        const errorMessage = responseData.errors.join('\n');
                        Swal.fire('Error', errorMessage, 'error');
                    } else {
                        Swal.fire('Error', 'An error occurred while updating the portfolio.', 'error');
                    }
                }
            }else {
                const response = await http.post('/admin/add_seoPortfolio', formData);
                console.log(response)
                const responseData = response.data;
            
                
                if (response.status === 200) {
                    Swal.fire('Success', responseData.msg, 'success');
                    setMsg(responseData.msg);
                    setShowMessage(true);
                    setSelectedModalIndustry('');
                    setSelectedModalTechnology('');
                    setSelectedModalCountry('');
                    setWebsiteLink('');
                    setContent('');
                    setTimeout(() => {
                        setShowMessage(false);
                    }, 5000);
                    setShowModal(false);
                    resetForm();
                    fetchData();
                } else {
                    // Handle validation errors
                    if (responseData.errors) {
                        const errorMessage = responseData.errors.join('\n');
                        Swal.fire('Error', errorMessage, 'error');
                    } else {
                        Swal.fire('Error', 'An error occurred while submitting the form.', 'error');
                    }
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire('Error', 'An error occurred while submitting the form.', 'error');
        }
    };
    const resetForm = () => {
        setSelectedIndustryItems([]);
        setSelectedModalTechnology(null);
        setSelectedModalCountry(null);
        setWebsiteLink('');
        setContent('');
    };
    const showConfirmationDialog = (id) => {
        if (window.confirm("Are you sure you want to delete this Portfolio?")) {
            handleDelete(id);
        }
    };
    const handleDelete = async (id) => {
        try {
            const res = await http.delete(`/admin/deleteSeoPortfolio/${id}`);
            setMsg(res.data.msg);
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
            Swal.fire('Success', res.data.msg, 'success');
            fetchData();
        } catch (error) {
            console.error('Error deleting country:', error);
        }
    };
    const placeholder = 'Start typing...';
    const config = useMemo(() => ({
        readonly: false,
        placeholder: placeholder
    }), [placeholder]);
    return (
        <>
        {loading ? ( // Show loading message when data is being fetched
                <div>Loading...</div>
            ) : (
        <div className="container">
            <div className="card">
            <div className="card-header d-flex justify-content-between">
                <div>
                    <button type="button" className="btn btn-success" onClick={() => setShowModal(true)}>
                        Add Portfolio
                    </button>
                </div>
                {showMessage && (
                    <div className="alert alert-success text-center" style={{ width: 'fit-content', margin: '0 auto' }}>
                        {msg}
                    </div>
                )}
            </div>

                <div className="card-body card-block">
                    <div className="col-md-12">
                        <div className="table-responsive m-b-40">
                        <table className="table table-data3 table-striped nowrap" width="100%" id="example" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th>S.no.</th>
                                        <th>Website Link</th>
                                        <th>KeyWord Rank</th>
                                        <th>
                                            <Select 
                                                className="filter" 
                                                options={[
                                                    { value: '', label: 'Select Industry', type: 'placeholder' },
                                                    ...industryOptions
                                                ]}
                                                placeholder={industryPlaceholderText} // Placeholder text for the search input
                                                value={selectedIndustry} 
                                                onChange={handleIndustryChange} 
                                            />
                                        </th>
                                        <th>
                                          <Select className="filter"
                                          options={[
                                            { value: '', label: 'Select Technology', type: 'placeholder' },
                                            ...technologyOptions
                                        ]}
                                           placeholder={technologyPlaceholderText}
                                           value={selectedTechnology} 
                                           onChange={handleTechnologyChange} />
                                        </th>
                                        <th>
                                          <Select className="filter"
                                          options={[
                                            { value: '', label: 'Select Country', type: 'placeholder' },
                                            ...countryOptions
                                        ]}
                                           placeholder={countryPlaceholderText}
                                           value={selectedCountry} 
                                           onChange={handleCountryChange} />
                                        </th>
                                        <th>Created By</th>
                                        <th>Updated By</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data
                                        .filter((item) => {
                                            const industryMatch = selectedIndustry === '' || (item.industry && item.industry.split(',').some(industryId => industryId.trim() === selectedIndustry.toString()));
                                            const technologyMatch = selectedTechnology === '' || item.technology.toString() == selectedTechnology;
                                            const countryMatch = selectedCountry === '' || item.country.toString() == selectedCountry;
                                            console.log('Country match:', countryMatch);
                                            return industryMatch && technologyMatch && countryMatch;
                                      })
                                        
                                        .map((list, index) => (
                                        <tr key={list.id}>
                                            <td>{index + 1}</td>
                                            <td>{list.website_link}</td>
                                            <td>
                                                <button type="button" className="btn btn-success viewKeyWord" data-id={list.id}>
                                                    {list.keywordRanking ? he.decode(list.keywordRanking).replace(/<\/?[^>]+(>|$)/g, "").split(/\s+/).slice(0, 3).join(' ') : ''}
                                                </button>
                                            </td>
                                            <td>
                                                {list.industry.split(',').map((industryId, i) => {
                                                    const industryName = industry.find(item => item.id === parseInt(industryId))?.industry;
                                                    return industryName;
                                                }).filter(industry => industry).join(', ')}
                                            </td>
                                         <td>
                                            {technology.find(tech => tech.id == list.technology)?.technology || 'Unknown'}
                                        </td>
                                        <td>
                                            {country.find(ctry => ctry.id == list.country)?.country || 'Unknown'}
                                        </td>
                                        <td>{list.created_by}</td>
                                        <td>{list.updated_by}</td>
                                        <td>
                                            <div className="edit-delete-icons">
                                            <button className="editlink btn btn-success" onClick={() => handleEdit(list.id)}>
                                            <i className="fa fa-edit edit-icon" aria-hidden="true"></i>
                                            </button>
                                            <button onClick={() => showConfirmationDialog(list.id)} className="btn btn-danger">
                                                <i className="fa fa-trash delete-icon" aria-hidden="true"></i>
                                            </button>
                                            </div>
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        <Modal show={showModal} onHide={handleCloseModal}>
    <Modal.Header closeButton>
    <Modal.Title>{editId ? 'Edit Seo Portfolio' : 'Add Seo Portfolio'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form id="portfolioForm" onSubmit={handleSubmit}>
        <input type="text" id="portfolioId" name="id" value={editId} />
        <div className="form-group">
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="industryDropdown" style={{maxWidth: '300px'}}>
                        Select Industry
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <div className="input-group mb-2">
                            <input
                                type="text"
                                className="form-control"
                                id="industrySearch"
                                placeholder="Search Industry"
                                onChange={handleModalSearchChange}
                            />
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                            <input
                                type="checkbox"
                                id="industry-all"
                                checked={selectedIndustryItems.length === filteredIndustry.length}
                                onChange={handleAllCheckboxIndustryChange}
                            />
                            <label htmlFor="industry-all">All</label>
                        </div>
                        {filteredIndustry.map((item) => {
                            const industryIds = Array.isArray(selectedModalIndustry) ? selectedModalIndustry : String(selectedModalIndustry).split(','); // Check if selectedIndustry is an array or convert to string and split
                            return (
                                <div className="dropdown-item" key={item.id}>
                                    <input
                                        type="checkbox"
                                        className="industry-checkbox"
                                        id={`industry-${item.id}`}
                                        value={item.id}
                                        checked={industryIds.includes(String(item.id))} // Check if the industry ID is included
                                        onChange={handleModalCheckboxChange}
                                    />
                                    <label htmlFor={`industry-${item.id}`}>&nbsp;{item.industry}</label>
                                </div>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            
            
            <div className="form-group">
                <label htmlFor="websiteLink">Website Link</label>
                <input type="text" className="form-control" id="websiteLink" value={websiteLink} onChange={(e) => setWebsiteLink(e.target.value)} />
            </div>
            
            <div className="form-group">
                <label htmlFor="country">Country</label>
                <Select
                                options={country.map(ctr => ({ value: ctr.id, label: ctr.country }))}
                                value={selectedModalCountry}
                                onChange={handleModalCountryChange}
                                isClearable={true}
                                placeholder="Select Country"
                            />
            </div>
            <div className="form-group">
                            <label htmlFor="keywordRanking">Keyword Ranking</label>
                            <JoditEditor
			ref={editor}
			value={content}
			config={config}
			tabIndex={1} // tabIndex of textarea
            onBlur={newContent => setContent(newContent)}
            onChange={newContent => setContent(newContent)}
		/>
                        </div>
            <div className="form-group">
                <label htmlFor="technology">Technology</label>
                <Select
                    
                    options={technology.map(tech => ({ value: tech.id, label: tech.technology }))}
                    value={selectedModalTechnology}
                    onChange={handleModalTechnologyChange}
                    isClearable={true}
                    placeholder="Select Technology"
                />
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                    Close
                </button>
                <button type="submit" className="btn btn-primary">
                {editId ? 'Update Seo Portfolio' : 'Add Seo Portfolio'}
                </button>
            </div>
        </form>
    </Modal.Body>
            </Modal>
            </>
    );
};

export default SeoPortfolio;
