import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';

function AddUser() {
    const [image, setImage] = useState(null);
    const [dob, setDob] = useState(null);

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
         const formData = new FormData();
        formData.append('photo', image);
        //formData.append('name', e.target.elements.name.value);
        const form = e.target;
        const formDataEntries = new FormData(form);
        formDataEntries.forEach((value, key) => {
            formData.append(key, value);
        });
       try {
            const response = await fetch('http://127.0.0.1:8000/api/admin/add-user', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <div className="container">
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <div>
                        <button type="button" className="btn btn-success" data-toggle="modal">
                            Add User
                        </button>
                    </div>
                    <div className="btn-group" style={{ marginBottom: '20px' }}>
                        <Link to="/admin/users">
                            <button type="button" className="btn btn-success" data-toggle="modal">
                                User List
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="card-body card-block">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="projectName1" className="form-control-label">Add Profile Photo</label>
                                    <img 
                                        id="static-image" 
                                        className="rounded-circle1 img-responsive1" 
                                        src="/images/logo-image.png"
                                        alt="Static Image" 
                                        style={{ 
                                            display: image ? 'none' : 'block',
                                            height: '192px', 
                                            width: '280px'   
                                        }} 
                                    />
                                    <img 
                                        id="uploaded-image" 
                                        className="rounded-circle1 img-responsive1" 
                                        src={image ? URL.createObjectURL(image) : ''} 
                                        alt="Uploaded Image" 
                                        style={{ 
                                            display: image ? 'block' : 'none',
                                            height: '192px', 
                                            width: '280px'   
                                        }} 
                                    />
                                    <input type="file" name="photo" id="photo-input" onChange={handleImageChange} />
                                </div>
                                <div classNameName="form-group">
                                    <label htmlFor="name" className="form-control-label">Name</label>
                                    <input type="text" id="name" name="name" className="form-control"  placeholder="Enter Details.." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-control-label">Personal Email</label>
                                    <input type="text" id="email" name="email" className="form-control"  placeholder="Enter Email.." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="gender" className="form-control-label">Gender</label>
                                    <select name="gender" className="form-control">
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="current_address" className="form-control-label">Present Address</label>
                                    <input type="text" id="current_address" name="current_address" className="form-control"  placeholder="Enter Address.." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="f_name" className="form-control-label">Father Name</label>
                                    <input type="text" id="f_name" name="f_name" className="form-control" placeholder="Enter Details.." />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="m_name" className="form-control-label">Mother Name</label>
                                    <input type="text" id="m_name" name="m_name" className="form-control" placeholder="Enter Details.." />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="type_user" className="form-control-label">Users Type</label>
                                    <select className="form-control" name="type_user">
                                        <option>Select type of user</option>
                                        <option value="1" >Sub-Admin</option>
                                        <option value="2">Sr Executive</option>
                                        <option value="3" >Executive</option>
                                        <option value="4" >Trainee</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="department" className="form-control-label">Position</label>
                                    <select className="form-control" name="department">
                                        <option>Select Position</option>
                                        <option value="1" >Business Development - Bidding</option>
                                        <option value="2" >Business Development - Email Marketing</option>
                                        <option value="3" >Human Resource</option>
                                        <option value="4" >Website Design and Development</option>
                                        <option value="5" >Mobile Application</option>
                                        <option value="6" >Digital Marketing (SEO)</option>
                                        <option value="7" >Content Writer</option>
                                        <option value="8" >Business Development - Linkedin</option>
                                        <option value="9" >Admin</option>
                                    </select>
                                </div>
                            <div className="form-group">
                                <label htmlFor="emp_id" className="form-control-label">Add Employee Id</label>
                                <input type="text" id="emp_id" name="emp_id" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="officialEmail" className="form-control-label">Official Email</label>
                                <input type="text" id="officialEmail" name="officialEmail" placeholder="Official Email.." className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobileNo" className="form-control-label">Mobile Number</label>
                                <input type="text" id="mobileNo" name="mobileNo" placeholder="Enter Number.." className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="permanent_address" className="form-control-label">Permanent Address</label>
                                <input type="text" id="permanent_address" name="permanent_address" placeholder="Enter Address.." className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="f_mobileNo" className="form-control-label">Father/Husband Phone no</label>
                                <input type="text" id="f_mobileNo" name="f_mobileNo" placeholder="Enter Number.." className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="m_mobileNo" className="form-control-label">Mother/Wife Phone no</label>
                                <input type="text" id="m_mobileNo" name="m_mobileNo" placeholder="Enter Number.." className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password" className="form-control-label">Password</label>
                                <input type="password" id="password" name="password" className="form-control" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="dob" className="form-control-label">Date of Birth</label>
                            <DatePicker
                                id="dob"
                                name="dob"
                                className="form-control"
                                selected={dob} 
                                onChange={date => setDob(date)} 
                                placeholderText="Enter DOB.." 
                                dateFormat="yyyy-MM-dd" 
                                showYearDropdown 
                                scrollableYearDropdown 
                                yearDropdownItemNumber={100} 
                            />
                        </div>
                            </div>
                            <div className="col-md-4">
                            <div className="form-group">
                            <label htmlFor="doj" className="form-control-label">Date of Joining</label>
                            <input type="date" id="doj" name="doj" placeholder="Enter Joining.." className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="qualification_graduate" className="form-control-label">Graduate</label>
                            <input type="text" id="qualification_graduate" name="qualification_graduate" placeholder="Enter qualification graduate.." className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="qualification_postgraduate" className="form-control-label">Post Graduate</label>
                            <input type="text" id="qualification_postgraduate" name="qualification_postgraduate" placeholder="Enter qualification postgraduate.." className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="total-work-exp" className="form-control-label">Employee Pre Company</label>
                            <div className="work-experience-group">
                                <div className="input-wrapper">
                                    <label htmlFor="total-work-exp">Total Exp</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="total-work-exp" name="total_work_exp" placeholder="work_exp" />
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="last-company-name">Last Company Name</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="last-company-name" name="last_company_name" placeholder="Last Company Name" />
                                    </div>
                                </div>
                                <div className="input-wrapper">
                                    <label htmlFor="pre-company-name">Pre Company Name</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="pre-company-name" name="pre_company_name" placeholder="Previous Company Name" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                                    <label htmlFor="document-collect" className="form-control-label">Document Collect</label>
                                    <div className="document-group">
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="past-company-toggle" name="past_company_record" value="1" />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="past-company-toggle" style={{ lineHeight: "30px" }}>Past Company Record</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="aadhar-card-toggle" className="toggle-input" name="aadhar_card" value="1" />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="aadhar-card-toggle" style={{ lineHeight: "30px" }}>Aadhar Card</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="pan-card-toggle" className="toggle-input" name="pan_card" value="1" />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="pan-card-toggle" style={{ lineHeight: "30px" }}>Pan Card</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="current-address-toggle" className="toggle-input" name="current_address_proof" value="1" />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="current-address-toggle" style={{ lineHeight: "30px" }}>Current Address Proof</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="permanent-address-toggle" className="toggle-input" name="permanent_address_proof" value="1" />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="permanent-address-toggle" style={{ lineHeight: "30px" }}>Permanent Address Proof</label>
                                        </div>
                                        <div>
                                            <label className="switch">
                                                <input type="checkbox" id="phone-number-toggle" className="toggle-input" name="phone_no_proof" value="1" />
                                                <span className="slider round"></span>
                                            </label>
                                            <label htmlFor="phone-number-toggle" style={{ lineHeight: "30px" }}>Phone Number Proof</label>
                                        </div>
                                    </div>
                                </div>

                        
                            </div>
                        </div>
                        <div className="card-footer">
                            <input type="submit" value="Submit" name="submit" className="btn btn-primary btn-sm" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddUser;
