import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <div className="sb-sidenav-menu-heading">  </div>
                    <Link className="nav-link" to="/admin/dashboard">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        <span className="link-text">Dashboard</span>
                    </Link>
                    <Link class="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseUsers" aria-expanded="false" aria-controls="collapseLayouts">
                            <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                            Users
                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </Link>
                    <div class="collapse" id="collapseUsers" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/admin/users">User List</Link>
                            <Link class="nav-link" to="/admin/add-user">Add Users</Link>
                        </nav>
                    </div>
                    <Link class="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseProject" aria-expanded="false" aria-controls="collapseLayouts">
                            <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                            Project
                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </Link>
                    <div class="collapse" id="collapseProject" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/admin/project">Project</Link>
                            <Link class="nav-link" to="/admin/add-project">Add Project</Link>
                        </nav>
                    </div>
                    <Link className="nav-link" to="/admin/report">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        <span className="link-text">Report</span>
                    </Link>
                    <Link className="nav-link" to="/admin/attendance">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        <span className="link-text">Attendance</span>
                    </Link>
                    <Link class="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseOtherTechnology" aria-expanded="false" aria-controls="collapseLayouts">
                            <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                            Other Technology
                            <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                    </Link>
                    <div class="collapse" id="collapseOtherTechnology" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav class="sb-sidenav-menu-nested nav">
                            <Link class="nav-link" to="/admin/technology">Technology</Link>
                            <Link class="nav-link" to="/admin/industry">Industry</Link>
                            <Link class="nav-link" to="/admin/plugin">Plugin</Link>
                            <Link class="nav-link" to="/admin/hosting">Hosting</Link>
                            <Link class="nav-link" to="/admin/country">Country</Link>
                            <Link class="nav-link" to="/admin/category">Category</Link>
                        </nav>
                    </div>
                    <Link className="nav-link" to="/admin/portfolio">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        <span className="link-text">PortFolio</span>
                    </Link>
                    <Link className="nav-link" to="/admin/seo-portfolio">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        <span className="link-text">Seo PortFolio</span>
                    </Link>
                    <Link className="nav-link" to="/admin/link-building-data">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        <span className="link-text">Link Building Data</span>
                    </Link>
                    {/*
                    <Link className="nav-link" to="/admin/leave">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        <span className="link-text">Leave</span>
                    </Link>
                  */}
                </div>
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">Logged in as:</div>
                Start Bootstrap
            </div>
        </nav>
    );
}

export default Sidebar;
